import { InAppService } from './in-app.service';
import { Injectable } from '@angular/core';
// import * as itemsMock from '../../../assets/json/items.json';
// import * as revenueTransactionMock from '../../../assets/json/revenueItems.json';

import {
  HttpClient,
  HttpParams,
  HttpErrorResponse,
} from '@angular/common/http';
import 'rxjs/Rx';
import 'rxjs/add/observable/of';
import { environment } from '../../../environments/environment.prod';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
@Injectable({ providedIn: 'root' })
export class ServerComService {
  url: string = environment.EnvironmentConfig.currentCountry;
  apiUrl: string = environment.EnvironmentConfig.backendHost;
  localUrl: string = environment.EnvironmentConfig.localHostPubliApi;
  mediaUrl2: string = environment.EnvironmentConfig.mediaUrl2;
  mediaUrl3 = 'https://cdn.designer-24.com/catalog/product';
  constructor(
    private httpClient: HttpClient,
    private inAppService: InAppService
  ) {}
  signInOrUp(body: any, method) {
    return this.httpClient
      .post<any>(this.apiUrl + 'customer/' + method, body)
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
  }

  loginGoogle(token: any) {
    // console.log(token);
    return this.httpClient
      .post<any>(
        this.apiUrl + 'customer/googleLogin?storeId=1&guestKey=null',
        token
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error) => {
          // console.log(error);
          return throwError('errorrrr');
        })
      );
  }

  loginFacebook(userData: any) {
    return this.httpClient
      .post<any>(
        this.apiUrl + 'customer/facebookLogin?storeId=1&guestKey=null',
        userData
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: Response) => {
          // console.log(error);
          return throwError('errorrrr');
        })
      );
  }

  sendEmailForResetPassword(options) {
    let params: HttpParams = new HttpParams();
    if (options.email && options.rUrl && options.storeId) {
      params = params.set('email', options.email);
      params = params.set('storeId', options.storeId);
      params = params.set('urlRedirect', options.rUrl);
      return this.httpClient
        .get<any>(this.apiUrl + 'password/forgot', { params: params })
        .pipe(
          map((data) => {
            return data;
          }),
          catchError((error: Response) => {
            return throwError('Something went wrong');
          })
        );
    }
  }
  getSupplierInfoUnMapped() {
    return this.httpClient.get<any>(this.apiUrl + 'supplier');
  }
  getStoreExtraConfig(storeId: string): Observable<{
    channel: { label: string; value: string }[];
    d24ConnectType: { label: string; value: string }[];
    strategy: { label: string; value: string }[];
  }> {
    let params = new HttpParams();
    params = params.set('storeId', storeId);
    return this.httpClient.get<{
      channel: { label: string; value: string }[];
      d24ConnectType: { label: string; value: string }[];
      strategy: { label: string; value: string }[];
    }>(this.apiUrl + 'getItemConfig', { params });
  }
  getSupplierInfoProfileMapped(): Observable<any> {
    return this.httpClient.get<any>(this.apiUrl + 'supplier').pipe(
      map((info) => {
        const data = info[0];
        const userId = data.supplier._key;
        const username = data.supplier.firstName + ' ' + data.supplier.lastName;
        const userMail = data.supplier.email;
        const key = data.supplier._key;
        const phone: string = data.supplier.phone + '';
        const contracts = data.supplier.contracts
          ? data.supplier.contracts
          : [];
        const userPhone = phone.substring(3, phone.length) + '';
        const userPhoneCode = phone.substring(0, 3);
        let profileImageName = data.supplier.image;
        let userHasImage: boolean;
        if (data.supplier.image && data.supplier.image.length > 0) {
          userHasImage = true;
        } else if (localStorage.getItem('socialImageLarge')) {
          profileImageName = localStorage.getItem('socialImageLarge');
          userHasImage = true;
        } else {
          userHasImage = false;
        }
        const organisationType =
          data.supplier.supplierInfo &&
          data.supplier.supplierInfo.organisationType
            ? data.supplier.supplierInfo.organisationType
            : '';
        const company =
          data.supplier.supplierInfo && data.supplier.supplierInfo.company
            ? data.supplier.supplierInfo.company
            : '';
        const tagBuild: any = [];
        if (
          data.supplier.supplierInfo &&
          data.supplier.supplierInfo.socialMediaLinks &&
          data.supplier.supplierInfo.socialMediaLinks.length > 0
        ) {
          const temp = data.supplier.supplierInfo.socialMediaLinks.split(',');
          for (let i = 0; i < temp.length; i++) {
            tagBuild.push({ value: temp[i], display: temp[i] });
          }
        }
        const tags = tagBuild;
        const website =
          data.supplier.supplierInfo && data.supplier.supplierInfo.website
            ? data.supplier.supplierInfo.website
            : '';
        const designsOwnBool =
          data.supplier.supplierInfo &&
          data.supplier.supplierInfo.designer &&
          data.supplier.supplierInfo.designer === 'yes';
        let designsOwn: string;
        const wallet = data.supplier.wallet;
        // console.log(wallet);
        if (designsOwnBool) {
          designsOwn = 'Yes';
        } else {
          designsOwn = 'No';
        }
        let address = data.supplier.address
          ? data.supplier.address[0]
          : undefined;
        let hasAddress: boolean;
        address === undefined ? (hasAddress = false) : (hasAddress = true);
        let addressName: string;
        if (hasAddress) {
          addressName = address.name;
          address =
            address.country + ', ' + address.city + ', ' + address.street;
        }
        let hasBankInfo = false;
        let bankInfo;
        let bankName;
        if (
          data.supplier.supplierInfo &&
          data.supplier.supplierInfo.bankAccountInfo
        ) {
          hasBankInfo = true;
          bankInfo = data.supplier.supplierInfo.bankAccountInfo;
          bankName = bankInfo.bankInfo.bank;
          bankInfo =
            bankInfo.personalInfo.accountNumber.substr(0, 3) + '********';
        }
        const socialLinks =
          data.supplier.supplierInfo &&
          data.supplier.supplierInfo.socialMediaLinks
            ? data.supplier.supplierInfo.socialMediaLinks
            : '';
        const finalObject: any = {
          userId: userId,
          userMail: userMail,
          userName: username,
          userPhone: userPhone,
          userPhoneCode: userPhoneCode,
          profileImageName: profileImageName,
          userHasImage: userHasImage,
          company: company,
          organisationType: organisationType,
          tags: tags,
          website: website,
          designsOwn: designsOwn,
          designsOwnBool: designsOwnBool,
          wallet: wallet,
          address: address,
          hasAddress: hasAddress,
          addressName: addressName,
          socialLinks: socialLinks,
          hasBankInfo: hasBankInfo,
          bankInfo: bankInfo,
          bankName: bankName,
          contracts: contracts,
          key: key,
        };
        return finalObject;
      }),
      catchError((error: Response) => {
        return throwError(error);
      })
    );
  }
  getDashboardWidgetData() {
    return this.httpClient
      .get<any>(this.apiUrl + 'getDashboardWidgetData')
      .pipe(
        map((data) => data[0]),
        catchError((error: Response) =>
          throwError(() => 'Something went wrong')
        )
      );
  }

  getTimelineData() {
    return this.httpClient.get<any>(this.apiUrl + 'getTimelineData').pipe(
      map((data) => data),
      catchError((error: Response) => throwError(() => 'Something went wrong'))
    );
  }

  getOrderData() {
    return this.httpClient.get<any>(this.apiUrl + 'getOrderData').pipe(
      map((data) => data),
      catchError((error: Response) => throwError(() => 'Something went wrong'))
    );
  }
  getSupplierInfo() {
    return this.httpClient.get<any>(this.apiUrl + 'supplier').pipe(
      map((data) => data[0]),
      catchError((error: Response) => throwError(() => 'Something went wrong'))
    );
  }

  putSupplierInfo(body: any) {
    return this.httpClient.put<any>(this.apiUrl + 'customer/update', body).pipe(
      map((data) => data),
      catchError((error: Response) => throwError(() => 'errorrrr'))
    );
  }

  addItem(body: any) {
    return this.httpClient.post<any>(this.apiUrl + 'item', body).pipe(
      map((data) => data),
      catchError((error: Response) => throwError(() => 'errorrrr'))
    );
  }
  getItems(options) {
    let params: HttpParams = new HttpParams();
    if (options && options.offset !== undefined) {
      // !== undefined since 0 doesnt pass without it
      params = params.set('offset', options.offset);
    }
    if (options && options.pageSize) {
      params = params.set('pageSize', options.pageSize);
    }
    return this.httpClient
      .get<any>(this.apiUrl + 'item', { params: params })
      .pipe(
        map((data) => {
          const items: any[] = [];
          for (const item of data[0].listItem) {
            items.push({
              designerName: item.item.designerName,
              category: item.item.category,
              size: item.item.countrySize + ' ' + item.item.size,
              status:
                item.process.status === 'rejected'
                  ? 'Not fit'
                  : this.inAppService.convertToPascalCase(item.process.status),
              submitDate: item.process.createdAt,
              imageUrls: item.item.images,
              frontImage:
                item.item && item.item.images ? item.item.images[0] : undefined,
              count: data[0].count,
              key: item.item._key,
            });
          }
          return items;
        }),
        catchError((e) => {
          return throwError(e);
        })
      );
  }
  getRevenueTransactions(version = 'v2') {
    const params = new HttpParams().append('version', version);
    const months = {
      '01': 'Jan',
      '02': 'Feb',
      '03': 'Mar',
      '04': 'Apr',
      '05': 'May',
      '06': 'June',
      '07': 'Jul',
      '08': 'Aug',
      '09': 'Sep',
      '10': 'Oct',
      '11': 'Nov',
      '12': 'Dec',
    };
    // return this.httpClient.get<any>(this.apiUrl+'revenueTransactions').map(
    // console.log(this.apiUrl+'revenueTransactions');
    const lebanonCurrency = version === 'v1' ? 'LBP' : 'USD';

    return this.httpClient
      .get<any>(this.apiUrl + 'revenueTransactions', { params })
      .pipe(
        map((data) => {
          const finalData: any = [];
          for (const item of data) {
            const map = { UAE: 'AED', LEB: 'LBP', JOR: 'JOD' };
            item.payments = (
              +this.inAppService[
                'currency' + map[item.suppplierRegion] + 'Rate'
              ] * +item.payments
            ).toFixed(2);
            const currentItem: any = {
              key: item._key,
              month: months[item.month] + ' ' + item.year,
              openingBalance:
                item.suppplierRegion === 'UAE'
                  ? 'AED ' +
                    parseInt(item.supplierOpBalanceAED, 10).toLocaleString(
                      'en-US'
                    )
                  : item.suppplierRegion === 'JOR'
                  ? 'USD ' +
                    parseInt(item.supplierOpBalanceUSD, 10).toLocaleString(
                      'en-US'
                    )
                  : lebanonCurrency +
                    ' ' +
                    parseInt(
                      item['supplierOpBalance' + lebanonCurrency],
                      10
                    ).toLocaleString('en-US'),
              earnings:
                item.suppplierRegion === 'UAE'
                  ? 'AED ' +
                    parseInt(item.totalNetEarningsAED, 10).toLocaleString(
                      'en-US'
                    )
                  : item.suppplierRegion === 'JOR'
                  ? 'USD ' +
                    parseInt(item.totalNetEarningsUSD, 10).toLocaleString(
                      'en-US'
                    )
                  : lebanonCurrency +
                    ' ' +
                    parseInt(
                      item['totalNetEarnings' + lebanonCurrency],
                      10
                    ).toLocaleString('en-US'),
              payments:
                item.suppplierRegion === 'UAE'
                  ? 'AED ' + item.payments.toLocaleString('en-US')
                  : item.suppplierRegion === 'JOR'
                  ? 'USD ' + item.payments.toLocaleString('en-US')
                  : lebanonCurrency +
                    ' ' +
                    (+item.payments).toLocaleString('en-US'),
              closingBalance:
                item.suppplierRegion === 'UAE'
                  ? 'AED ' +
                    parseInt(item.clBalanceAED, 10).toLocaleString('en-US')
                  : item.suppplierRegion === 'JOR'
                  ? 'USD ' +
                    parseInt(item.clBalanceUSD, 10).toLocaleString('en-US')
                  : lebanonCurrency +
                    ' ' +
                    parseInt(
                      item['clBalance' + lebanonCurrency],
                      10
                    ).toLocaleString('en-US'),
              items: [],
            };
            finalData.push(currentItem);
            // console.log(currentItem);
          }
          return finalData;
        }),
        catchError((e) => {
          console.log(e);
          return throwError(e);
        })
      );
  }
  getPaymentHistory() {
    return this.httpClient.get<any>(this.apiUrl + 'paymentHistory').pipe(
      map((data) => {
        return data;
      }),
      catchError((e) => {
        return throwError(e);
      })
    );
  }
  getEligibleForPayment() {
    return this.httpClient.get<any>(this.apiUrl + 'eligibleForPayment').pipe(
      map((data) => {
        return data;
      }),
      catchError((e) => {
        return throwError(e);
      })
    );
  }

  transferToWallet(body: any) {
    return this.httpClient.post<any>(this.apiUrl + 'payment/wallet', body).pipe(
      map((data) => {
        return data;
      }),
      catchError((error: Response) => {
        return throwError('errorrrr');
      })
    );
  }

  getSupplierClosingBalance(supplierKey: string) {
    let params: HttpParams = new HttpParams();
    params = params.set('supplierKey', supplierKey);
    return this.httpClient
      .get<any>(this.apiUrl + 'supplier/balance', { params: params })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: Response) => {
          return throwError('errorrrr');
        })
      );
  }

  checkSwiftCode(swiftCode: string) {
    return this.httpClient
      .get<any>(this.apiUrl + 'swiftValidated?swift=' + swiftCode)
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((e) => {
          return throwError(e);
        })
      );
  }
  checkIbanCode(ibanCode: string) {
    return this.httpClient
      .get<any>(this.apiUrl + 'ibanValidated?iban=' + ibanCode)
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((e) => {
          return throwError(e);
        })
      );
  }
  getRevenueTransactionItem(revID) {
    let params: HttpParams = new HttpParams();
    if (revID) {
      params = params.set('revID', revID);
    }
    const lebanonCurrency =
      this.inAppService.currencyVersion === 'v1' ? 'LBP' : 'USD';
    return this.httpClient
      .get<any>(this.apiUrl + 'revenueTransactionItem', { params: params })
      .pipe(
        map((data) => {
          // data = revenueTransactionMock;
          const finalData: any = [];

          for (const item of data) {
            let dryAndHandleUSD, dryAndHandleAED, dryAndHandleLBP;
            if (
              item.netEarningsUSD !== null &&
              item.miscellaneousUSD !== null
            ) {
              dryAndHandleUSD = (
                Math.round(
                  (parseFloat(item.dryCleaningUSD) +
                    parseFloat(item.miscellaneousUSD)) *
                    100
                ) / 100
              ).toFixed(0);
            }
            if (
              item.netEarningsAED !== null &&
              item.miscellaneousAED !== null
            ) {
              dryAndHandleAED = (
                Math.round(
                  (parseFloat(item.dryCleaningAED) +
                    parseFloat(item.miscellaneousAED)) *
                    100
                ) / 100
              ).toFixed(0);
            }
            if (
              item.netEarningsLBP !== null &&
              item.miscellaneousLBP !== null
            ) {
              dryAndHandleLBP = (
                Math.round(
                  (parseFloat(item.dryCleaningLBP) +
                    parseFloat(item.miscellaneousLBP)) *
                    100
                ) / 100
              ).toFixed(0);
            }

            if (lebanonCurrency !== 'LBP') {
              dryAndHandleLBP = JSON.parse(JSON.stringify(dryAndHandleUSD));
            }

            const currentItem: any = {
              key: item._key,
              images: [this.mediaUrl3 + item.image],
              designer: item.designerName,
              dateRented: item.rentalDeliveryDate,
              amountRentedFor:
                item.rentalStore === 'UAE'
                  ? 'AED ' +
                    parseInt(item.rentalValueAED, 10).toLocaleString('en-US')
                  : item.rentalStore === 'JOR'
                  ? 'USD ' +
                    parseInt(item.rentalValueUSD, 10).toLocaleString('en-US')
                  : lebanonCurrency +
                    ' ' +
                    parseInt(
                      item['rentalValue' + lebanonCurrency],
                      10
                    ).toLocaleString('en-US'),
              netEarnings:
                item.rentalStore === 'UAE'
                  ? 'AED ' +
                    parseInt(item.netEarningsAED, 10).toLocaleString('en-US')
                  : item.rentalStore === 'JOR'
                  ? 'USD ' +
                    parseInt(item.netEarningsUSD, 10).toLocaleString('en-US')
                  : lebanonCurrency +
                    ' ' +
                    parseInt(
                      item['netEarnings' + lebanonCurrency],
                      10
                    ).toLocaleString('en-US'),
              dryCleaningAndHandlingCharges:
                item.rentalStore === 'UAE'
                  ? 'AED ' +
                    (dryAndHandleAED
                      ? parseInt(dryAndHandleAED, 10).toLocaleString('en-US')
                      : 'null')
                  : item.rentalStore === 'JOR'
                  ? 'USD ' +
                    (dryAndHandleUSD
                      ? parseInt(dryAndHandleUSD, 10).toLocaleString('en-US')
                      : 'null')
                  : lebanonCurrency +
                    ' ' +
                    (dryAndHandleLBP
                      ? parseInt(dryAndHandleLBP, 10).toLocaleString('en-US')
                      : 'null'),
              netRentalValue:
                item.rentalStore === 'UAE'
                  ? 'AED ' +
                    parseInt(item.netRentalValueAED, 10).toLocaleString('en-US')
                  : item.rentalStore === 'JOR'
                  ? 'USD ' +
                    parseInt(item.netRentalValueUSD, 10).toLocaleString('en-US')
                  : lebanonCurrency +
                    ' ' +
                    parseInt(
                      item['netRentalValue' + lebanonCurrency],
                      10
                    ).toLocaleString('en-US'),
              earningsBeforeTax:
                item.rentalStore === 'UAE'
                  ? 'AED ' +
                    parseInt(item.supplierEarningsAED, 10).toLocaleString(
                      'en-US'
                    )
                  : item.rentalStore === 'JOR'
                  ? 'USD ' +
                    parseInt(item.supplierEarningUSD, 10).toLocaleString(
                      'en-US'
                    )
                  : lebanonCurrency +
                    ' ' +
                    parseInt(
                      item['supplierEarning' + lebanonCurrency],
                      10
                    ).toLocaleString('en-US'),
              tax:
                item.rentalStore === 'UAE'
                  ? 'AED ' + parseInt(item.taxAED, 10).toLocaleString('en-US')
                  : item.rentalStore === 'JOR'
                  ? 'USD ' + parseInt(item.taxUSD, 10).toLocaleString('en-US')
                  : lebanonCurrency +
                    ' ' +
                    parseInt(item['tax' + lebanonCurrency], 10).toLocaleString(
                      'en-US'
                    ),
              isZero:
                (item.rentalStore === 'UAE' && item.netEarningsAED === '0') ||
                (item.rentalStore === 'JOR' && item.netEarningsUSD === '0') ||
                (item.rentalStore === 'LEB' && item.netEarningsLBP === '0') ||
                (item.rentalStore === 'LEB' &&
                  lebanonCurrency === 'USD' &&
                  item.netEarningsUSD === '0')
                  ? true
                  : false,
              orderedOn: item.orderedOn,
            };
            if (item.status) {
              currentItem.status = item.status;
            }
            finalData.push(currentItem);
            // console.log(currentItem);
          }
          return finalData;
        }),
        catchError((e) => {
          console.log(e);
          return throwError(e);
        })
      );
  }
  addAddress(body) {
    return this.httpClient
      .post<any>(this.apiUrl + 'customer/address', body)
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: Response) => {
          console.log(error);
          return throwError('errorrrr');
        })
      );
  }
  editAddress(body) {
    return this.httpClient
      .put<any>(this.apiUrl + 'customer/address', body)
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: Response) => {
          console.log(error);
          return throwError('errorrrr');
        })
      );
  }
  getItemInfoUnMapped(itemKey) {
    return this.httpClient.get<any>(this.apiUrl + 'oneItem?id=' + itemKey);
  }
  getItemInfo(itemKey) {
    return this.httpClient.get<any>(this.apiUrl + 'oneItem?id=' + itemKey).pipe(
      map((data) => {
        return data;
      }),
      catchError((error: Response) => {
        console.log(error);
        return throwError('errorrrr');
      })
    );
  }
  editItem(body) {
    return this.httpClient.put<any>(this.apiUrl + 'item', body).pipe(
      map((data) => {
        return data;
      }),
      catchError((error: Response) => {
        return throwError('errorrrr');
      })
    );
  }
  addFacebookMail(body: any) {
    return this.httpClient
      .post<any>(this.url + 'd24public/user/updatesocialwithemail', body)
      .pipe(
        map((data) => {
          const finalData = [];
          finalData.push(data.create_success, data.error_msg);
          if (data.isUsed !== undefined) {
            finalData.push(data.isUsed);
          }

          return finalData;
        }),
        catchError((error: Response) => {
          console.log(error);
          return throwError('Something went wrong');
        })
      );
  }

  checkLoggedIn(body: any) {
    return this.httpClient
      .post<any>(this.url + 'd24public/authenticate', body)
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: Response) => {
          return throwError('login auth Failed');
        })
      );
  }
  getClosetItems(
    start: number,
    pageCount: number,
    sort: string,
    sortType: string
  ) {
    let prefCurrency;
    if (
      this.inAppService.storedSupplierInfo &&
      this.inAppService.storedSupplierInfo.supplierInfo &&
      this.inAppService.storedSupplierInfo.supplierInfo.preferredCurrency
    ) {
      prefCurrency =
        this.inAppService.storedSupplierInfo.supplierInfo.preferredCurrency;
    } else {
      prefCurrency = 'USD';
    }
    return this.httpClient
      .get<any>(
        this.apiUrl +
          'getClosetItems?offset=' +
          start +
          '&pageSize=' +
          pageCount +
          '&sort=' +
          sort +
          '&sortType=' +
          sortType +
          '&preferredCurrency=' +
          prefCurrency
      )
      .pipe(
        map((data) => {
          // data = itemsMock;
          const itemInfo: any[] = [];
          for (const item of data[0].page) {
            const designer: string = item.product.DesignerName;
            const baseUrl = environment.EnvironmentConfig.currentCountry;
            const images: string[] = [];
            const id = item.item._key;
            if (item.product.allImages) {
              for (let i = 0; i < item.product.allImages.length; i++) {
                images.push(
                  'https://d2e5dbv9809opl.cloudfront.net/catalog/product' +
                    item.product.allImages[i]
                );
              }
            }
            itemInfo.push({
              id,
              frontImage:
                item.product.frontImage && item.product.frontImage !== null
                  ? 'https://cdn.designer-24.com/catalog/product' +
                    item.product.frontImage
                  : 'https://cdn.designer-24.com/resized/offline_image.jpg',
              imageUrls: images,
              url:
                item.item.store === 1
                  ? baseUrl + item.product.urlKey + '?noredirect=true'
                  : baseUrl + 'lb/' + item.product.urlKey,
              designerName: this.inAppService.convertToPascalCase(designer),
              favorites: item.product.favsCount
                ? item.product.favsCount < 0
                  ? 0
                  : item.product.favsCount
                : 0,
              rentals: item.item.rentalsCount
                ? item.item.rentalsCount < 0
                  ? 0
                  : item.item.rentalsCount
                : 0,
              status: item.enabled ? 'Live' : 'Offline',
              totalRevenue: item.item.totalRevenue
                ? (+item.item.totalRevenue).toFixed(2)
                : '0',
              totalCount: data[0].count,
              type: item.item.type,
            });
          }
          return itemInfo;
        }),
        catchError(() => {
          return throwError('closetFail');
        })
      );
  }

  getOrders(start: number, pageCount: number, sort: string, sortType: string) {
    let prefCurrency; // = this.inAppService.storedSupplierInfo.supplierInfo.preferredCurrency;
    if (
      this.inAppService.storedSupplierInfo &&
      this.inAppService.storedSupplierInfo.supplierInfo &&
      this.inAppService.storedSupplierInfo.supplierInfo.preferredCurrency
    ) {
      prefCurrency =
        this.inAppService.storedSupplierInfo.supplierInfo.preferredCurrency;
    } else {
      prefCurrency = 'USD';
    }
    return this.httpClient
      .get<any>(
        this.apiUrl +
          'getClosetItems?offset=' +
          start +
          '&pageSize=' +
          pageCount +
          '&sort=' +
          sort +
          '&sortType=' +
          sortType +
          '&preferredCurrency=' +
          prefCurrency
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(() => {
          return throwError('rentalsFail');
        })
      );
  }

  resetPassword(body: any) {
    return this.httpClient.post<any>(this.apiUrl + 'password/reset', body);
  }
  sendSms(options) {
    let params: HttpParams = new HttpParams();
    params = params.set('phone', options.phone);
    if (options.makeDefault) {
      params = params.set('default', 'true');
    }
    if (options.generatePhoneCall) {
      params = params.set('generatePhonecall', 'true');
    }
    return this.httpClient
      .get<any>(this.apiUrl + 'verify/sms/setup', { params: params })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(() => {
          return throwError('errorrrr');
        })
      );
  }
  validatePhone(code, token) {
    let params: HttpParams = new HttpParams();
    params = params.set('code', code);
    params = params.set('token', token);
    return this.httpClient
      .get<any>(this.apiUrl + 'verify/sms', { params: params })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: Response) => {
          return throwError('errorrrr');
        })
      );
  }
  requestPayment(body: any) {
    return this.httpClient
      .post<any>(this.apiUrl + 'payment/request', body)
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(() => {
          return throwError('errorrrr');
        })
      );
  }
  registerNewsLetter(email: any) {
    const body = {
      email: email,
    };
    return this.httpClient
      .post<any>(this.apiUrl + 'supplier/subscribe', body)
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(() => {
          return throwError('newsletter Failed');
        })
      );
  }
  getFooter() {
    return this.httpClient.get<any>(this.apiUrl + 'getSupplierFooter').pipe(
      map((data) => {
        return data;
      }),
      catchError(() => {
        return throwError('errorrrr');
      })
    );
  }
}
