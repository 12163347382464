// tslint:disable-next-line:import-blacklist
import { Observable } from 'rxjs';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { Injectable } from '@angular/core';
import { InAppService, SharedFunctionsService } from '../../shared/services';

@Injectable({ providedIn: 'root' })
export class PreAuthGuard {
  constructor(
    private router: Router,
    private inAppService: InAppService,
    private _functions: SharedFunctionsService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
     const user =
       this._functions.getCookie('currentUser') &&
       !this._functions.isCookieExpired('currentUser') &&
       !localStorage.getItem('currentUser')
         ? this._functions.getCookie('currentUser')
         : null;
    if (user != null && !localStorage.getItem('currentUser')) {
      localStorage.setItem('currentUser', user);
    }
    if (localStorage.getItem('currentUser')) {
      if (
        (!this.inAppService.phoneVerfied || !this.inAppService.isSupplier) &&
        !this.inAppService.firstTimeLoad
      ) {
        localStorage.clear();
        this.inAppService.notLoggedIn = true;
        return true;
      } else {
        this.router.navigate(['admin/my-dashboard']);
        this.inAppService.notLoggedIn = false;
      }
    } else {
      this.inAppService.notLoggedIn = true;
      return true;
    }
  }
  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.canActivate(route, state);
  }
}
