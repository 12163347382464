import { Injectable, EventEmitter } from '@angular/core';
import { HttpRequest } from '@angular/common/http';
// tslint:disable-next-line:import-blacklist
import { Subject } from 'rxjs';
@Injectable({ providedIn: 'root' })
export class InAppService {
  constructor() {}
  firstTimeLoad = true;
  eligibleForPayment: boolean;
  previousUrl: string;
  isSupplier = false;
  phoneVerfied = false;
  tempItemId: string;
  showStatusMessage = new EventEmitter<Object>();
  showExtraButtons: boolean;
  storableStatusMessage: any = null;
  supplierAddresses: any[];
  supplierHasAnAddress = false;
  uploadRequest = new Subject<HttpRequest<any>>();
  storedSupplierInfo: any = {};
  currentProfilePicture = new Subject<any>();
  triggerUploadErrorModalAddItem = new Subject<any>();
  submitItemUpload = new Subject<any>();
  submitItemRenamedImages: any[] = [];
  triggerUniversalModal: Subject<any> = new Subject<any>();
  triggerInfoBox: Subject<any> = new Subject<any>();
  storedTableColumns: string[];
  closeItemViewPopup: Subject<void> = new Subject<void>();
  supplierStore;
  triggerFileUploadChecks: Subject<any> = new Subject<any>();
  triggerAfterFileUpload: Subject<any> = new Subject<any>();
  triggerUpload: Subject<any> = new Subject<any>();
  promptBank: boolean;
  storedPhoneObject: any = {};
  storedMonthlyPotential = '$ - - -';
  updateMonthlyPotential = new EventEmitter<boolean>();
  morphFixInfo = new EventEmitter<boolean>();
  forceMorph = new EventEmitter<boolean>();
  updatePhoneVerified = new EventEmitter<boolean>();
  hideSignOptions = new EventEmitter<boolean>();
  currentCategory: string;
  submitMoreItems: boolean;
  beginItemSubmit: boolean;
  notLoggedIn: boolean;
  currencies = {
    AED: {
      code: 'AED',
      USD1: '3.6738',
      precision: 2,
    },
    USD: {
      code: 'USD',
      USD1: '1',
      precision: 2,
    },
    LBP: {
      code: 'LBP',
      USD1: '1500',
      precision: 1,
    },
    JOD: {
      code: 'JOD',
      USD1: '0.71',
      precision: 2,
    },
  };
  currencyAEDRate = Number(this.currencies['AED'].USD1);
  currencyLBPRate = Number(this.currencies['LBP'].USD1);
  currencyJODRate = Number(this.currencies['JOD'].USD1);
  currencyVersion = 'v1';
  ////////////////////////////////////////

  // ///// FUNCTIONS FOR IN APP USE NOT HTTP RELATED
  renameImageToResized(imageName, xTimes): string {
    let newName = '';
    const namePieces: string[] = imageName ? imageName.split('.') : [];
    for (let i = 0; i < namePieces.length; i++) {
      if (i < namePieces.length - 1) {
        newName += namePieces[i] + '.';
      } else {
        newName =
          newName.substring(0, newName.length - 1) +
          '_resized_' +
          xTimes +
          'x.' +
          namePieces[i];
      }
    }
    return newName;
  }
  getParameterByName(name, url?) {
    if (!url) {
      url = window.location.href;
    }
    name = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) {
      return null;
    }
    if (!results[2]) {
      return '';
    }
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }
  renameImageArrayToResized(imageArray, xTimes): string[] {
    for (let i = 0; i < imageArray.length; i++) {
      imageArray[i] = this.renameImageToResized(imageArray[i], xTimes);
    }
    return imageArray;
  }
  renameImageArrayToOriginal(imageArray): string[] {
    for (let i = 0; i < imageArray.length; i++) {
      imageArray[i] = this.renameImageToOriginal(imageArray[i]);
    }
    return imageArray;
  }
  renameImageToOriginal(imageName: string): string {
    let newName = '';
    newName = imageName.replace('_resized_75x', '');
    return newName;
  }
  isTouchScreen() {
    if ('ontouchstart' in document.documentElement) {
      return true;
    } else {
      return false;
    }
  }
  replaceAt(text: string, index: number, replacement: string) {
    const newText =
      text.substring(0, index) +
      replacement +
      text.substring(index + 1, text.length);
    return newText;
  }
  trimParenthesis(text: string): string {
    // for(let i=0; i<text.length;i++) {
    //   if(text.charAt(i) == '(' || text.charAt(i) == ')') {
    //     text = this.replaceAt(text, i, '');
    //   }
    // }
    // return text;
    let newText = '';
    newText = text.replace(/\(/g, '');
    newText = newText.replace(/\)/g, '');
    return newText;
  }
  changeFileName(file: File, name: string): File {
    const newFile: any = {};
    for (const key in file) {
      // //console.log(key);
      if (key !== 'name') {
        newFile[key] = file[key];
      }
    }
    newFile['name'] = name;
    return <File>newFile;
  }
  public blobToFile(blob: Blob, name: string, lastModified: number): File {
    const file: any = blob;
    file.name = name;
    file.lastModified = lastModified;
    return <File>file;
  }
  addTimeStamp(fileName: string) {
    const date = new Date();
    const timeStamp: string = date.getTime() + '';
    let reachedInsertable = false;
    for (let i = fileName.length - 1; i >= 0; i--) {
      if (fileName.charAt(i) === '.') {
        reachedInsertable = true;
      } else if (reachedInsertable) {
        fileName =
          fileName.substring(0, i + 1) +
          timeStamp +
          fileName.substring(i + 1, fileName.length);

        return fileName;
      }
    }
  }
  pascalCaseFromArray(text: string[], between: string) {
    let finalText = '';
    for (let i = 0; i < text.length; i++) {
      let temp = text[i].toLowerCase();
      temp = temp.substr(0, 1).toUpperCase() + temp.substr(1, temp.length);
      if (i < text.length - 1) {
        finalText += temp + between;
      } else {
        finalText += temp;
      }
    }
    return finalText;
  }
  convertToPascalCase(text: string) {
    let firstSplit: string[] = text.split('-');
    const firstSection = '';
    if (firstSplit.length > 1) {
      return this.pascalCaseFromArray(firstSplit, '-');
    } else {
      firstSplit = text.split(' ');
      return this.pascalCaseFromArray(firstSplit, ' ');
    }
  }
  convertObjectArrayToCommaSeparatedString(array: Array<any>): string {
    let finalString = '';
    for (let i = 0; i < array.length; i++) {
      finalString +=
        i < array.length - 1 ? array[i].value + ', ' : array[i].value;
    }
    return finalString;
  }
  convertArrayToCommaSeparatedString(array: Array<string>): string {
    let finalString = '';
    for (let i = 0; i < array.length; i++) {
      finalString += i < array.length - 1 ? array[i] + ', ' : array[i];
    }
    return finalString;
  }
  convertCommaSeparatedStringToArray(string: string): Array<any> {
    const toTrim = string.split(',');
    for (let i = 0; i < toTrim.length; i++) {
      toTrim[i] = toTrim[i].trim();
    }
    return toTrim;
  }
  convertStringToPascalCase(text: string) {
    const returnValue: string = text.toLowerCase();
    const firstLetter = returnValue.substr(0, 1).toUpperCase();
    return firstLetter + returnValue.substr(1, returnValue.length);
  }
}
