<div class="centered-container flex-container space-between login-container">
    <div class="sign-in-ad">
      <div class="sign-in-message">
        <h1 class="message">Start earning<br> money in just a<br> few steps!</h1>
      </div>
    </div>
    <div class="login-form">
      <div class="register-step2">
          <h1 class="register-step2-title">Just one more step!</h1>
          <form (ngSubmit)="onSubmit(f, $event)" #f="ngForm">
              <app-alert-box [location]="'additional'" class="mainAlert"></app-alert-box>
            <div class="phone-number input-wrapper">
                <input id="valCodeInput" (keyup)="validateCode()" (focusout)="validateCode()" type="number"class="code"
                placeholder="{{placeHolders[4]}}" required [(ngModel)]="mobileCode" #code="ngModel" name="mCode" maxlength="3">
              <input id="valNumInput" (keyup)="validateNumber()" (focusout)="validateNumber()" class="number"
                type="number" placeholder="{{placeHolders[2]}}" required [(ngModel)]="mobileValue" #mobile="ngModel" name="mobile">
            </div>
            <div class="currency add-arrow input-wrapper">
                <ng-select class="input" [searchable]="false" [items]="currencyList" [multiple]="false" [(ngModel)]="chosenCurrency"
                name="currencyChosen" placeholder="Prefered Currency" required [clearable]="false">
              </ng-select>
            </div>
            <div class="organisation add-arrow input-wrapper">
              <ng-select class="input" [searchable]="false" [items]="organisation" [multiple]="false" [(ngModel)]="organisationType"
                name="organisationType" placeholder="Organisation Type" required [clearable]="false">
              </ng-select>
            </div>
            <div class="more-validation" *ngIf = "organisationType && organisationType != 'Regular' && organisationType !='' ">
              <div class="break-line"></div>
              <form>
                <input  name="website" type= "text" class="input-wrapper" placeholder="Website" [(ngModel)]="this.website">
                <input  name="socialLinks" type= "text" class="input-wrapper" placeholder="Instagram" [(ngModel)]="this.tags">
                <div *ngIf = "organisationType == 'Shop/Boutique' || organisationType == 'Company'" >
                  <input name="company" type="text" class="input-wrapper" placeholder="{{organisationType + ' name'}}" [(ngModel)]="this.company">
                </div>
              </form>
            </div>
            <p class="conditions">By registering your details, you agree with our Terms & Conditions, and Privacy and Cookie Policy.</p>
            <input type="submit" class="supplier-button solid-button" value="Sign up" (click)="nextStep = true">
          </form>
      </div>
    </div>
</div>
