import { Observable } from 'rxjs';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, NavigationEnd } from '@angular/router';
import { Injectable } from '@angular/core';
import { InAppService } from '../../../../../shared';

@Injectable()
export class PhoneVerifyGuard  {
  constructor(
    private inAppService: InAppService,
    private router: Router,
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (this.inAppService.firstTimeLoad) {
      this.router.navigate(['admin/submitted-items']);
    } else if (this.inAppService.phoneVerfied === true) {
      if (this.inAppService.previousUrl) {
        this.router.navigate([this.inAppService.previousUrl]);
      } else {
        this.router.navigate(['/']);
      }
    } else {
      return true;
    }
  }
}
