<div class="default-padding">
  <div class="centered-container flex-container logsign forget">
    <div class="logsign-container">
      <h1 class="logsign-breadcrumb">Reset password:</h1>
      <h2 class="logsign-description">Please enter your registered email address & we will send you a link to reset your password right away!</h2>
      <form (ngSubmit)="onSubmit(f, $event)" #f="ngForm" class="logsign-form">
        <app-alert-box [location]="'logsign'" class="mainAlert"></app-alert-box>
        <div class="logsign-input">
          <input name="mail" type="mail" class="input" placeholder="Email Address" required [(ngModel)]="emailValue"
            [pattern]="this.regexMail" #email="ngModel">
        </div>
        <div class="logsign-input submit">
          <input type="submit" class="input" value="Reset Password">
        </div>
      </form>
    </div>
  </div>
</div>