import { Observable } from 'rxjs';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { InAppService } from '../../../../../shared';

@Injectable()
export class AdditionalInfoGuard  {
  constructor(private inAppService: InAppService, private router: Router) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (this.inAppService.firstTimeLoad) {
      this.router.navigate(['admin/submitted-items']);
    } else if (this.inAppService.isSupplier === true) {
      if (this.inAppService.previousUrl) {
        this.router.navigate([this.inAppService.previousUrl]);
      } else {
        this.router.navigate(['/']);
      }
    } else {
      return true;
    }
  }
}
