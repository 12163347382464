import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ViewItemComponent } from './view-item.component';
import { CarouselModule } from 'ngx-bootstrap/carousel';

@NgModule({
  imports: [CommonModule, CarouselModule],
  declarations: [ViewItemComponent],
  exports: [ViewItemComponent],
})
export class ViewItemModule {}
