import { AfterViewInit } from '@angular/core';
// tslint:disable-next-line:import-blacklist
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm, NgModel } from '@angular/forms';

import text from '../../../../../../../assets/json/appTexts.json';
import { HttpErrorResponse } from '@angular/common/http';

import {
  SocialAuthService,
  FacebookLoginProvider,
  GoogleLoginProvider,
} from '@abacritt/angularx-social-login';

import { envStrings } from '../../../../../../../environments/envStrings';
import { environment } from '../../../../../../../environments/environment.prod';
import {
  ServerComService,
  InAppService,
  SharedFunctionsService,
} from '../../../../../../shared';
import { filter, take } from 'rxjs/operators';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
// tslint:disable-next-line:one-line
export class LoginComponent implements OnInit, AfterViewInit {
  baseUrl: string = environment.EnvironmentConfig.currentCountry;
  mediaUrl: string = environment.EnvironmentConfig.mediaUrl;
  passType: String = 'password';
  closed = true;
  currentPassEye: string =
    this.mediaUrl + 'angularapp/prodAssets/passEyeClosed.png';
  altPassEye: string = this.mediaUrl + 'angularapp/prodAssets/passEyeOpen.png';
  redirectUrl: string;
  formValid: boolean;
  regexMail: RegExp = new RegExp(
    // tslint:disable-next-line:max-line-length
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  nosocial = false;
  safeToLeave = false;
  submitting = false;
  heading = text.logsign.login.heading;
  dataLayer: any = ((<any>window).dataLayer = (<any>window).dataLayer || []);
  @ViewChild('f') signupForm: NgForm;
  @ViewChild('email') emailInput: NgModel;
  @ViewChild('pass') passInput: NgModel;

  private services: Subscription[] = [];
  continueEmail = false;

  constructor(
    private authService: SocialAuthService,
    private serviceCom: ServerComService,
    private router: Router,
    private serverCom: ServerComService,
    private inAppService: InAppService,
    private _functions: SharedFunctionsService
  ) {}

  ngOnInit() {
    localStorage.clear();
    this.inAppService.forceMorph.emit(true);
    this.authService.authState
      .pipe(
        filter((user) => !!user),
        take(1)
      )
      .subscribe(async (user) => {
        console.log(user);
        const authToken = await this.authService.getAccessToken(
          GoogleLoginProvider.PROVIDER_ID
        );
        user.authToken = authToken;
        this.serviceCom.loginGoogle(user).subscribe(
          (data) => {
            localStorage.setItem('currentUser', data[0].tokens.accessToken);
            this._functions.setCookie(
              'currentUser',
              data[0].tokens.accessToken,
              30
            );
            localStorage.setItem('userMail', data[0].userInfo.email);
            this.dataLayer.push({
              event: 'supplierLoginGoogle',
              outcome: 'success',
            });
            this.router.navigate(['admin/my-dashboard']);
            this.inAppService.notLoggedIn = false;
          },
          (error) => {
            this.dataLayer.push({
              event: 'supplierLoginGoogle',
              outcome: 'failure',
            });
            this.inAppService.showStatusMessage.emit({
              type: 'danger',
              text: envStrings.strings.logsign.errorMsgs[1],
              location: 'logsign',
            });
          }
        );
      });
  }

  ngAfterViewInit() {
    if (this.inAppService.storableStatusMessage !== null) {
      this.inAppService.showStatusMessage.next(
        this.inAppService.storableStatusMessage
      );
      this.inAppService.storableStatusMessage = null;
    }
  }
  signIn() {
    if (this.signupForm.valid) {
      this.submitting = true;
      const email: string = this.emailInput.value;
      const password: string = encodeURIComponent(this.passInput.value);
      const body = {
        email: email,
        password: {
          value: password,
          isEncrypted: false,
        },
        storeId: '1',
        fromSupplier: true,
      };
      // let body  = `email=${email}&storeId='1'&password=${password}&fromSupplier=true`;
      this.services.push(
        this.serverCom.signInOrUp(body, 'login').subscribe(
          (data) => {
            if (data[0]) {
              this.dataLayer.push({
                event: 'supplierLoginEmail',
                outcome: 'success',
              });
              localStorage.setItem('currentUser', data[0].tokens.accessToken);
              this._functions.setCookie(
                'currentUser',
                data[0].tokens.accessToken,
                30
              );
              localStorage.setItem('userMail', data[0].userInfo.email);
              this.router.navigate(['admin/my-dashboard']);
              this.inAppService.notLoggedIn = false;
            }
          },
          (error: HttpErrorResponse) => {
            if (error.status === 401) {
              if (error.error.code && error.error.message) {
                this.dataLayer.push({
                  event: 'supplierRegisterEmail',
                  outcome: 'failure',
                });
                this.inAppService.showStatusMessage.emit({
                  type: 'danger',
                  text: error.error.message,
                  location: 'logsign',
                });
                this.submitting = false;
              }
            }
            this.submitting = false;
            {
              throw error;
            }
          }
        )
      );
    } else {
      this.inAppService.showStatusMessage.next({
        type: 'danger',
        text: envStrings.storedJSONs.standardErrorMessages[0],
        location: 'logsign',
      });
    }
  }

  authFB() {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
    this.authService.authState.subscribe((user) => {
      this.serviceCom.loginFacebook(user).subscribe(
        (data) => {
          const response = data;
          if (response[0].userInfo && response[0].userInfo.email === '') {
            this.dataLayer.push({
              event: 'supplierLoginFacebook',
              outcome: 'no email success',
            });
            this.nosocial = true;
            if (!data.image) {
              if (data.social && data.social.facebook) {
                localStorage.setItem(
                  'socialImageSmall',
                  data.social.facebook.picture.small
                );
                localStorage.setItem(
                  'socialImageLarge',
                  data.social.facebook.picture.large
                );
              }
            }
            localStorage.setItem('currentUser', response[0].tokens.accessToken);
            this._functions.setCookie(
              'currentUser',
              response[0].tokens.accessToken,
              30
            );
            localStorage.setItem('userMail', response[0].userInfo.email);
            this.router.navigate(['auth/nosocialmail']);
          } else {
            localStorage.setItem('currentUser', response[0].tokens.accessToken);
            this._functions.setCookie(
              'currentUser',
              response[0].tokens.accessToken,
              30
            );
            localStorage.setItem('userMail', response[0].userInfo.email);
            this.router.navigate(['admin/my-dashboard']);
            this.inAppService.notLoggedIn = false;
            this.dataLayer.push({
              event: 'supplierLoginFacebook',
              outcome: 'success',
            });
          }
        },
        (error) => {
          this.dataLayer.push({
            event: 'supplierLoginFacebook',
            outcome: 'failure',
          });
          this.inAppService.showStatusMessage.next({
            type: 'danger',
            text: envStrings.strings.logsign.errorMsgs[1],
            location: 'logsign',
          });
          // {throw(error)};
        }
      );
    });
  }

  signInWithFB(): void {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }

  togglePassEye() {
    this.closed = !this.closed;
    if (!this.closed) {
      this.passType = 'text';
    } else {
      this.passType = 'password';
    }
    const temp = this.currentPassEye;
    this.currentPassEye = this.altPassEye;
    this.altPassEye = temp;
  }
  onSubmit(form: NgForm, event: Event) {
    this.signIn();
  }
  goToForgot() {
    this.safeToLeave = true;
    this.router.navigate(['auth/forgot']);
  }
  goToRegister() {
    this.safeToLeave = true;
    this.router.navigate(['auth/register']);
  }
}
