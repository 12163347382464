<div class="default-padding">
  <div class="centered-container flex-container logsign phone-verify">
    <div class="logsign-container">
      <h1 class="logsign-title" *ngIf="!(verificationSent && !codeVerified)">{{heading}} <span *ngIf="!verificationSent && !newPhoneNeeded && !codeVerified">"+
          {{phoneNumber}}"</span></h1>
      <h1 class="logsign-title" *ngIf="verificationSent && !codeVerified">Enter verification code</h1>
      <h2 class="logsign-description">
        <span *ngIf="!verificationSent && !newPhoneNeeded && !codeVerified && !phoneInUse">If
          this is your phone number, click VERIFY to get a verification code on your phone to confirm your identity,
          If this is not you, then provide your number by pressing NOT ME</span>
        <span *ngIf="verificationSent && !codeVerified">Please enter the verification code sent to
          +{{mobileCode}}{{phoneNumber}}
        </span>
        <span *ngIf="phoneInUse && !codeVerified && !verificationSent">The
          phone number you are trying to verify is already registered to the email address: {{takenEmail}}</span>
      </h2>
      <form (ngSubmit)="onSubmit(f, $event)" #f="ngForm" class="logsign-form">
        <app-alert-box [location]="'logsign'" class="mainAlert"></app-alert-box>
        <div class="logsign-input phone" *ngIf="newPhoneNeeded">
          <input id="valCodeInput" (keyup)="validateCode()" (focusout)="validateCode()" class="input" type="number"
            placeholder="{{placeHolders[4]}}" required [(ngModel)]="mobileCode" #code="ngModel" name="mCode" maxlength="3">
          <input id="valNumInput" (keyup)="validateNumber()" (focusout)="validateNumber()" class="input"
            type="number" placeholder="{{placeHolders[2]}}" required [(ngModel)]="phoneNumber" #mobile="ngModel" name="mobile">
        </div>
        <div class="logsign-input code" *ngIf="verificationSent && !codeVerified">
          <input name="c1" type="text" class="input" [(ngModel)]="c1" (keyup)="goToNextInput(0, $event)" maxlength="1"
            id="c0" autocomplete="off">
          <input name="c2" type="text" class="input" [(ngModel)]="c2" (keyup)="goToNextInput(1, $event)" maxlength="1"
            id="c1" autocomplete="off">
          <input name="c3" type="text" class="input" [(ngModel)]="c3" (keyup)="goToNextInput(2, $event)" maxlength="1"
            id="c2" autocomplete="off">
          <input name="c4" type="text" class="input" [(ngModel)]="c4" (keyup)="goToNextInput(3, $event)" maxlength="1"
            id="c3" autocomplete="off">
        </div>
        <div class="countdown" *ngIf="verificationSent && !codeVerified">{{altTimer}}</div>
        <div class="logsign-extra flex-container space-between" *ngIf="verificationSent && !codeVerified">
          <div class="message code">Didn't get a code? <button type="button" (click)="resendCode(requestPhoneCall)" [disabled]="!showAlternate">{{requestPhoneCall? "Request a phone call" : "Resend"}}</button></div>
        </div>
        <div class="logsign-input submit" *ngIf="(verificationSent && !codeVerified) || phoneInUse">
          <input type="submit" class="input" value="VERIFY" [disabled]="submitting">
        </div>
        <div class="logsign-input submit" *ngIf="!codeVerified && !(verificationSent && !codeVerified) && !phoneInUse">
          <input type="submit" class="input" value="VERIFY" [disabled]="submitting">
          <input type="button" class="input secondary-input" value="NOT ME" (click)="notMyNumber()" *ngIf="!verificationSent && !newPhoneNeeded"
            [disabled]="submitting">
        </div>
      </form>
    </div>
  </div>
</div>

<!-- <div class="col-sm-12 formContainer b-shadowM">
  <form (ngSubmit)="onSubmit(f, $event)" #f="ngForm">
    <span class="topRightX" (click)="goBack() "><i class="icofont icofont-arrow-left backBtn"></i></span>
    <div class="row m-b-20">
      <div class="col-md-12">
        <span class="f-regular noUpperDesk l-1 l-2m text-left">{{heading}}</span>
        <span class="f-regular noUpperDesk l-1 l-2m text-left" *ngIf="!verificationSent && !newPhoneNeeded && !codeVerified">"
          + {{phoneNumber}} "</span>
        <p class="f-regular l-1 l-1m text-left m-b-0 m-t-10" *ngIf="!verificationSent && !newPhoneNeeded && !codeVerified && !phoneInUse">If
          this is your phone number, click "VERIFY" to get a verification code on your phone to confirm your identity,
          If this is not you, then provide your number by pressing "NOT ME"</p>
        <p class="f-regular l-1 l-1m text-left m-b-0 m-t-10" *ngIf="verificationSent && !codeVerified">We've sent a
          code to " {{mobileCode}}{{phoneNumber}}". Please enter the code you received here.</p>
        <p class="f-regular l-1 l-1m text-left m-b-0 m-t-10" *ngIf="phoneInUse && !codeVerified && !verificationSent">The
          phone number you are trying to verify is already registered to the email address: {{takenEmail}}</p>
        <hr />
      </div>
    </div>

    <app-alert-box [location]="'logsign'" class="mainAlert"></app-alert-box>

    <div class="row" *ngIf="verificationSent && !codeVerified">
      <div class="col-3 p-l-0">
        <input name="c1" type="text" class="form-control miniInput" (keyup)="goToNextInput(0, $event)" [(ngModel)]="c1"
          maxlength="1" id="c0">
      </div>
      <div class="col-3">
        <input name="c2" type="text" class="form-control miniInput" [(ngModel)]="c2" (keyup)="goToNextInput(1, $event)"
          maxlength="1" id="c1">
      </div>
      <div class="col-3">
        <input name="c3" type="text" class="form-control miniInput" [(ngModel)]="c3" (keyup)="goToNextInput(2, $event)"
          maxlength="1" id="c2">
      </div>
      <div class="col-3 p-r-0">
        <input name="c4" type="text" class="form-control miniInput" [(ngModel)]="c4" id="c3" maxlength="1" (keyup)="goToNextInput(3, $event)">
      </div>
    </div>
    <span class="md-line"></span>
    <div *ngIf="newPhoneNeeded" class="row m-b-20">
      <div class="{{mobileCodeWidth}} inputFieldDiv nopaddingR">
        <span class="fixedPlus2">+</span>
        <input id="valCodeInput" (keyup)="validateCode($event)" (focusout)="validateCode($event)" style="padding-left:20px"
          class="form-control customInput noIconVal joinedL nopaddingR" type="number" placeholder="{{placeHolders[4]}}"
          required [(ngModel)]="mobileCode" #code="ngModel" name="mCode" maxlength="3">
      </div>
      <div style="float:right;" class="{{mobileValueWidth}} nopaddingL inputFieldDiv">
        <input id="valNumInput" (keyup)="validateNumber($event)" (focusout)="validateNumber($event)" class="form-control customInput joinedR"
          type="number" placeholder="{{placeHolders[2]}}" required [(ngModel)]="phoneNumber" #mobile="ngModel" name="mobile">
      </div>
    </div>
    <span class="md-line"></span>
    <div class="row m-t-25 text-left" *ngIf="verificationSent && !codeVerified">
      <div class="col-6 forgot-phone text-left">
        <a (click)="getCode()" class="customLink"> Didn't get a code? Click here to send again</a>
      </div>
      <div class="col-6 forgot-phone text-right">
        <a (click)="notMyNumber()" class="customLink">Not you? Change number here</a>
      </div>
    </div>
    <div class="row m-t-25 text-left" *ngIf="verificationSent && !codeVerified">
      <div class="col-12 forgot-phone text-left">
        <a *ngIf="!showAlternate" class="customLink">
          If you don't receive a message within <strong>{{altTimer}}</strong> seconds,
          you will be able to receive a call instead with your verification code.
        </a>
        <a *ngIf="showAlternate" (click)="getCode(true)" class="customLink">
          Click here to get a call with your verification code.
        </a>
      </div>
    </div>
    <div class="row m-t-30 maxWidthMob" *ngIf="!codeVerified && !(verificationSent && !codeVerified)">
      <div class="col-md-12">
        <button type="submit" *ngIf="!submitting" class="btn btn-primary btn-md btn-block waves-effect text-center m-b-20">VERIFY</button>
        <button type="button" (click)="notMyNumber()" *ngIf="!submitting && !verificationSent && !newPhoneNeeded" class="btn btn-secondary btn-md btn-block waves-effect text-center m-b-20">NOT
          ME</button>
        <button type="button" *ngIf="submitting" disabled class="btn btn-primary btn-md btn-block waves-effect text-center m-b-20">VERIFY</button>
      </div>
    </div>
  </form>
</div> -->
