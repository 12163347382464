<div class="centered-container flex-container space-between login-container">
  <div class="sign-in-ad">
    <div class="sign-in-message">
      <h1 class="message">Start earning<br> money in just a<br> few steps!</h1>
    </div>
  </div>
  <div class="login-form">
    <div *ngIf = "!continueEmail" class="sign-in">
        <h1 class="login-title">Login to your account.</h1>
        <div class="logsign-social">
            <div class="logsign-input fb" (click)="signInWithFB()">
              <p>Continue with Facebook</p>
            </div>
            <asl-google-signin-button type='standard' size='large' width="323" ></asl-google-signin-button>
        </div>
        <div class="line-breaker">or</div>
        <div class="logsign-email">
          <button type="button" class="supplier-button solid-button form-link" (click)="continueEmail = true">Log in with Email</button>
        </div>
    </div>
    <div *ngIf= "continueEmail" class="sign-in">
      <h1 class="login-step2-title">Continue with <a class="facebook" (click)="continueEmail = false">Facebook</a> or <a class="google" (click)="continueEmail = false">Google</a></h1>
      <div class="line-breaker">or</div>
      <form (ngSubmit)="onSubmit(f, $event)" #f="ngForm">
          <app-alert-box [location]="'logsign'" class="mainAlert"></app-alert-box>
        <input type = "text" name="email" class="input-wrapper" placeholder="Email Address" required ngModel [pattern]="this.regexMail"
        #email="ngModel" minlength="1" >
        <input type = "password" name="pass" class="input-wrapper" placeholder="Password" required [type]="this.passType"
        required ngModel #pass="ngModel" >
      <a class="forgot-pass" routerLink="/auth/forgot">Forgot password?</a>
      <input type= "submit" class="supplier-button solid-button" value="Log in" >
    </form>
    </div>
    

    <div class="line-breaker-full"></div>
    <div class="sign-up" >
      <p class="sign-up-message">Don’t have an account?</p>
      <div class="logsign-input new-login" routerLink="/auth/register">
        <a class="register">Sign up</a>
      </div>
    </div>

  </div>
</div>
