import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertBoxComponent } from './components';
import { RouterModule } from '@angular/router';
import { AlertModule } from 'ngx-bootstrap/alert';

@NgModule({
  imports: [CommonModule, RouterModule, AlertModule],
  declarations: [AlertBoxComponent],
  exports: [AlertBoxComponent],
})
export class AlertBoxModule {}
