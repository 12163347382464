import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { Injectable } from '@angular/core';
import { ServerComService, InAppService } from '../../shared';
import { catchError, map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthGuard {
  constructor(
    private serverCom: ServerComService,
    private router: Router,
    private inAppService: InAppService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (this.inAppService.firstTimeLoad) {
      if (localStorage.getItem('currentUser')) {
        return this.serverCom.getSupplierInfo().pipe(
          map((data) => {
            this.inAppService.storedSupplierInfo = data?.supplier;
            this.inAppService.firstTimeLoad = false;
            if (
              data?.supplier?.isSupplier &&
              data?.supplier?.phone &&
              data?.supplier?.verified
            ) {
              this.inAppService.isSupplier = true;
              this.inAppService.phoneVerfied = true;
              if (!localStorage.getItem('currentUser')) {
                this.inAppService.isSupplier = false;
                this.inAppService.phoneVerfied = false;
                this.router.navigate(['auth/login']);
                this.inAppService.notLoggedIn = true;
              } else {
                this.inAppService.notLoggedIn = false;
                return true;
              }
            } else if (
              data?.supplier?.isSupplier &&
              data?.supplier?.phone &&
              !data?.supplier?.verified
            ) {
              this.inAppService.isSupplier = true;
              this.inAppService.storedPhoneObject.code = data.supplier.code;
              this.inAppService.storedPhoneObject.phone = data.supplier.phone;
              const phoneVal: string = this.inAppService.storedPhoneObject.phone
                ? this.inAppService.storedPhoneObject.phone.toString()
                : undefined;
              this.inAppService.storedPhoneObject.phone = phoneVal
                ? phoneVal.replace(data.supplier.code, '')
                : undefined;
              if (!localStorage.getItem('currentUser')) {
                this.inAppService.isSupplier = false;
                this.router.navigate(['auth/login']);
                this.inAppService.notLoggedIn = true;
              } else {
                this.router.navigate(['auth/phoneVerify']);
                this.inAppService.notLoggedIn = true;
              }
            } else {
              this.inAppService.isSupplier = false;
              this.router.navigate(['auth/login']);
              this.inAppService.notLoggedIn = true;
            }
          }),
          catchError((e) => {
            return throwError(e);
          })
        );
      } else {
        // //console.log('in here 9999');
        this.inAppService.notLoggedIn = true;
        this.router.navigate(['auth/register']);
      }
    } else {
      if (!localStorage.getItem('currentUser')) {
        this.inAppService.isSupplier = false;
        this.inAppService.notLoggedIn = true;
        this.router.navigate(['auth/login']);
      } else if (!this.inAppService.isSupplier) {
        this.inAppService.notLoggedIn = true;
        this.router.navigate(['auth/additional']);
      } else if (!this.inAppService.phoneVerfied) {
        this.inAppService.notLoggedIn = true;
        this.router.navigate(['auth/phoneVerify']);
      } else {
        this.inAppService.notLoggedIn = false;
        return true;
      }
    }
    // /////////////////////////////////////////////
  }
  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.canActivate(route, state);
  }
}
