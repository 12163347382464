import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { OnInit, Injectable, TemplateRef } from '@angular/core';

@Injectable({providedIn:'root'})
export class PopUpService {
  bsModalRef: BsModalRef;
  config = {
    animated: true,
    keyboard: true,
    backdrop: true,
    ignoreBackdropClick: false,
  };
  constructor(private modalService: BsModalService) {}

  createPopUp(template: TemplateRef<any>) {
    this.bsModalRef = this.modalService.show(
      template,
      Object.assign({}, this.config, { class: 'wideModal' }),
    );
  }
  closePopUp() {
    this.bsModalRef.hide();
  }
}
