import { Router } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import text from '../../../../../../../assets/json/appTexts.json';
import { NgForm } from '@angular/forms';

import { envStrings } from '../../../../../../../environments/envStrings';
import { InAppService, PhoneNumberValidationService, ServerComService } from '../../../../../../shared';

@Component({
  selector: 'app-phone-verify',
  templateUrl: './phone-verify.component.html',
  styleUrls: ['./phone-verify.component.css'],
})
export class PhoneVerifyComponent implements OnInit, OnDestroy {
  heading = text.phoneVerify.heading;
  c1: number;
  c2: number;
  c3: number;
  c4: number;
  verCode: string; // = this.c1+''+this.c2+''+this.c3+''+this.c4;
  submitting = false;
  phoneNumber: string;
  newPhoneNeeded = false;
  verificationSent = false;
  phoneValid = true;
  codeVerified = false;
  mobileCode = '';
  placeHolders: string[] = envStrings.strings.checkout.addressForm.placeholders;
  currentToken: string;
  takenEmail: string;
  phoneInUse: boolean;
  makePhoneDefault = false;
  showAlternate = false;
  requestPhoneCall = false;
  mode = 'getCode';
  altTimer = 60;

  constructor(
    private inAppService: InAppService,
    private serverCom: ServerComService,
    private router: Router,
    private phoneValidationService: PhoneNumberValidationService

  ) {}

  ngOnInit() {
    // this.mobileCode = this.inAppService.storedPhoneObject.code;
    // this.phoneNumber = this.inAppService.storedPhoneObject.phone;
    // console.log(this.inAppService.storedSupplierInfo);
    this.phoneNumber = this.inAppService.storedSupplierInfo.phone;
    this.inAppService.notLoggedIn = false;
    this.inAppService.hideSignOptions.emit(true);
  }
  ngOnDestroy() {
    this.inAppService.hideSignOptions.emit(false);
  }
  validateCode() {
    const input = <HTMLInputElement>document.getElementById('valCodeInput');
    if (this.phoneValidationService.isValidCountryCode(parseInt(this.mobileCode, 10))) {
      input.className = 'input ng-dirty ng-valid ng-touched';
    } else {
      input.className = 'input ng-dirty ng-invalid ng-touched';
    }

    if (this.phoneNumber) {
      this.validateNumber();
    }
  }
  validateNumber() {
    const input = <HTMLInputElement>document.getElementById('valNumInput');
    if ( this.phoneValidationService.isValidNumber(this.mobileCode, this.phoneNumber)) {
      input.className = 'input ng-dirty ng-valid ng-touched';
      this.phoneValid = true;
    } else {
      this.phoneValid = false;
      input.className = 'input ng-dirty ng-invalid ng-touched';
    }
  }
  getCode(getAsVoice?: boolean) {
    this.submitting = true;
    this.showAlternate = false;
    // console.log(this.mobileCode);
    const body: any = {
      phone: this.mobileCode + '' + this.phoneNumber,
    };
    if (this.makePhoneDefault) {
      body.makeDefault = true;
    }
    // console.log(getAsVoice);
    if (getAsVoice) {
      body.generatePhoneCall = true;
      // console.log(body);
    }
    this.serverCom.sendSms(body).subscribe(
      (data) => {
        // console.log(data);
        if (data.email) {
          const mail1: string = data.email.split('@')[0];
          const mail2: string = data.email.split('@')[1];
          const i1: number = (<string>data.email).indexOf('@');
          // console.log(i1);
          this.takenEmail =
            mail1.substr(0, 3) +
            '*****@' +
            mail2.substr(0, 2) +
            '******, either log in to this email or continue to verify your number for the current email.';
          this.phoneInUse = true;
          this.submitting = false;
          this.makePhoneDefault = true;
        } else {
          this.newPhoneNeeded = false;
          this.heading = 'Is this you?';
          this.verificationSent = true;
          this.submitting = false;
          this.mode = 'checkCode';
          if (!getAsVoice) {
            this.currentToken = data.userToken;
          }
          setTimeout(() => {
            (<HTMLInputElement>document.getElementById('c0')).focus();
          }, 500);
          this.altTimer = 60;
          const timer = setInterval(() => {
            this.altTimer--;
          }, 1000);
          setTimeout(() => {
            this.showAlternate = true;
            clearInterval(timer);
          }, 60000);
        }
      },
      (e) => {
        this.inAppService.showStatusMessage.next({
          text: 'Oops! something went wrong please refresh and try again',
          location: 'logsign',
          type: 'danger',
        });
        // console.log(e);
      }
    );
  }
  submitCode() {
    this.submitting = true;
    this.serverCom.validatePhone(this.verCode, this.currentToken).subscribe(
      (data) => {
        // console.log(data);
        this.submitting = false;
        if ((data && data[0] && data[0].verified) || (data && data === true)) {
          this.inAppService.phoneVerfied = true;
          this.inAppService.updatePhoneVerified.emit(true);
          this.router.navigate(['admin/submitted-items']);
          setTimeout(() => {
            this.inAppService.showStatusMessage.next({
              text: 'Phone number verified.',
              location: 'addItem',
              type: 'success',
            });
          }, 1000);
        }
      },
      (e) => {
        this.clearInputs();
        this.submitting = false;
        this.inAppService.showStatusMessage.next({
          text: 'The code you entered seems to be incorrect please try again.',
          location: 'logsign',
          type: 'danger',
        });
      }
    );
  }
  resendCode(phoneCall?: boolean) {
    this.getCode(phoneCall);
    this.requestPhoneCall = true;
    setTimeout(() => {
      this.requestPhoneCall = false;
      this.showAlternate = false;
    }, 240000);
  }
  notMyNumber() {
    this.mobileCode = '';
    this.phoneNumber = '';
    this.newPhoneNeeded = true;
    this.verificationSent = false;
    this.mode = 'getCode';
    this.heading = 'Enter your phone number below';
  }
  clearInputs() {
    for (let i = 0; i < 4; i++) {
      const input = <HTMLInputElement>document.getElementById('c' + i);
      input.value = '';
    }
    (<HTMLInputElement>document.getElementById('c0')).focus();
  }
  goToNextInput(index: number, event: Event) {
    // document.getEle
    // console.log(event);
    const pressedValid =
      event['key'] !== 'Backspace' &&
      event['key'] !== 'ArrowRight' &&
      event['key'] !== 'ArrowLeft' &&
      event['key'] !== 'ArrowUp' &&
      event['key'] !== 'ArrowDown' &&
      event['key'] !== 'Tab';
    if (index < 3 && pressedValid) {
      const newFocus = <HTMLInputElement>(
        document.getElementById('c' + (index + 1))
      );
      newFocus.focus();
      // console.log(newFocus, index);
    } else if (pressedValid) {
      this.verCode = this.c1 + '' + this.c2 + '' + this.c3 + '' + this.c4;
      // console.log(this.verCode);
    } else if (event['key'] === 'Backspace') {
      const currentFocus = <HTMLInputElement>(
        document.getElementById('c' + index)
      );
      if (currentFocus.value.length === 0) {
        if (index - 1 >= 0) {
          const newFocus = <HTMLInputElement>(
            document.getElementById('c' + (index - 1))
          );
          newFocus.value = ''; // clear value
          newFocus.focus();
          // console.log(newFocus, index);
        } else {
          (<HTMLInputElement>document.getElementById('c0')).focus();
        }
      }
    } else if (event['key'] === 'Tab') {
      (<HTMLInputElement>document.getElementById('c' + (index - 1))).focus();
    }
  }
  goBack() {
    // // revert BG IMAGE
    // document.getElementById('pageBody').className = 'bodyTempClass';
    // //
    // let mail = localStorage.getItem('userMail') ? localStorage.getItem('userMail') : '';
    // localStorage.clear();
    // localStorage.setItem('userMail', mail);
    this.inAppService.isSupplier = false;
    this.inAppService.phoneVerfied = false;
    this.router.navigate(['auth/additional']);
  }
  onSubmit(form: NgForm, event: Event) {
    // console.log('subnation');
    if (this.mode === 'getCode' && form.valid && this.phoneValid) {
      // console.log('getCode');
      this.getCode();
    } else if (this.mode === 'checkCode' && form.valid) {
      // console.log('checkCode');
      this.submitCode();
    }
  }
}
