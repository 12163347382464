<h1 mat-dialog-title>{{ data.title }}</h1>
<div mat-dialog-content>
  <p>{{ data.message }}</p>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">{{ data.cancelText }}</button>
  <button mat-button cdkFocusInitial (click)="onYesClick()">
    {{ data.acceptText }}
  </button>
</div>
