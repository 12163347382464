import { Injectable } from '@angular/core';

declare const fbq: any;

@Injectable({ providedIn: 'root' })
export class SharedFunctionsService {
  baseUrl: string;
  fixBodyFromTop = 0;
  isFixed = false;
  adjustSimpleAccordian = false;
  userAgent = window.navigator.userAgent;
  iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
  is11orLower =
    this.userAgent.indexOf('Trident/') > 0 ||
    this.userAgent.indexOf('MSIE ') > 0;

  constructor() {}

  setCookie(cname, cvalue, exdays, msTime: number = null) {
    const d = new Date();
    d.setTime(d.getTime() + (msTime ? msTime : exdays * 24 * 60 * 60 * 1000));
    const expires = 'expires=' + d.toUTCString();
    document.cookie =
      cname + '=' + cvalue + ';' + expires + ';domain= designer-24.com; path=/';
  }

  deleteCookie(name: string) {
    this.setCookie(name, '', -1);
  }
  getCookie(cname) {
    const name = cname + '=';
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }

  checkCookie(cname) {
    const user = this.getCookie(cname);
    if (user !== '') {
      return true;
    } else {
      return false;
    }
  }

  isCookieExpired(cookieName: string): boolean {
    const cookieValue = this.getCookie(cookieName);
    if (cookieValue) {
      const expiresPart = cookieValue
        .split(';')
        .find((part) => part.trim().startsWith('expires='));
      if (expiresPart) {
        const expirationDate = new Date(expiresPart.split('=')[1]);
        return expirationDate < new Date();
      }
    }
    return true;
  }
}
