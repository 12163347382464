import { Component, OnInit, Input } from '@angular/core';
import { ServerComService, InAppService } from '../..';

@Component({
  selector: 'app-view-item',
  templateUrl: './view-item.component.html',
  styleUrls: ['./view-item.component.css'],

  styles: [
    `
      .bannerStyle h1 {
        background-color: #ccc;
        min-height: 300px;
        text-align: center;
        line-height: 300px;
      }
      .leftRs {
        position: absolute;
        margin: auto;
        top: 0;
        bottom: 0;
        width: 35px;
        height: 35px;
        border-radius: 999px;
        left: 0;
        opacity: 0.6;
        border: none;
        cursor: pointer;
      }

      .rightRs {
        position: absolute;
        margin: auto;
        top: 0;
        bottom: 0;
        width: 35px;
        height: 35px;
        border-radius: 999px;
        right: 0;
        opacity: 0.6;
        border: none;
        cursor: pointer;
      }
      .rightRs:focus,
      .leftRs:focus {
        outline: none;
      }
    `,
  ],
})
export class ViewItemComponent implements OnInit {
  currentItem: any = {};
  hasDescription = false;
  hasLinkToProd = false;
  isClosetItem = false;
  host = 'https://s3.eu-west-1.amazonaws.com/d24.staging.suppliers/';
  @Input() incomingItem: any;
  @Input() resizeAmount: number;
  localInAppService: InAppService;
  constructor(
    private serverCom: ServerComService,
    private inAppService: InAppService
  ) {}

  ngOnInit() {
    this.localInAppService = this.inAppService;

    if (this.incomingItem.id) {
      this.isClosetItem = false;
      // console.log(this.host);
      this.getViewableInfo();
    } else {
      this.openViewableItem();
      this.host = '';
      this.isClosetItem = true;
    }
  }
  openViewableItem() {
    // console.log(this.incomingItem);
    // this.incomingItem.imageUrls = this.inAppService.renameImageArrayToResized(this.incomingItem.imageUrls, 320);
    this.currentItem = this.incomingItem;
  }
  getViewableInfo() {
    this.serverCom.getItemInfo(this.incomingItem.id).subscribe(
      (data) => {
        const currentItem = {
          category: data[0].item.category,
          color: data[0].item.color,
          condition: data[0].item.condition,
          size: data[0].item.countrySize + ' ' + data[0].item.size,
          createdAt: data[0].item.createdAt,
          designerName: data[0].item.designerName,
          linkToProduct: data[0].item.linkToProduct,
          retailPrice: data[0].item.retailPrice,
          store: data[0].item.store,
          description: data[0].item.description,
          imageUrls: data[0].item.images,
          status: this.inAppService.convertToPascalCase(data[0].process.status),
        };
        this.currentItem = currentItem;
        // console.log(currentItem);
      },
      (e) => {
        // console.log(e)
      },
      () => {
        if (
          this.currentItem.description &&
          this.currentItem.description.length > 0
        ) {
          this.hasDescription = true;
        }
        if (
          this.currentItem.linkToProduct &&
          this.currentItem.linkToProduct.length > 0
        ) {
          this.hasLinkToProd = true;
        }
      }
    );
  }
  public myfunc(event: Event) {
    // carouselLoad will trigger this funnction when your load value reaches
    // it is helps to load the data by parts to increase the performance of the app
    // must use feature to all carousel
  }
}
