import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UniversalModalComponent } from './universal-modal.component';
import { ViewItemModule } from '../view-item';

@NgModule({
  imports: [CommonModule, ViewItemModule],
  declarations: [UniversalModalComponent],
  exports: [UniversalModalComponent],
})
export class UniversalModalModule {}
