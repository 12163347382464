// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  EnvironmentConfig: {
    appName: 'Supplier Production',
    clientId: 'e3c2b7d5-8703-4086-a097-a85b87c51759',
    backendHost: 'https://api.designer-24.com/',
    currentCountry: 'https://www.designer-24.com/',
    mediaUrl: 'https://s3-eu-west-1.amazonaws.com/d24.web.store.magento.media/',
    mediaUrl3: 'https://s3-eu-west-1.amazonaws.com/d24publiccontent/',
    mediaUrl4: 'https://s3.eu-west-1.amazonaws.com/d24.staging.suppliers/',
    localHostPubliApi: 'http://localhost:4200/',
    mediaUrl2: 'https://d2e5dbv9809opl.cloudfront.net/catalog/product/',
    facebookkID: '167211943854631',
    googleID:
      '593375133630-9imotvlvec5kli7tl5i4068dcl0808h6.apps.googleusercontent.com',
  },
};
