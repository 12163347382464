import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import {
  Component,
  OnInit,
  Input,
  TemplateRef,
  OnDestroy,
} from '@angular/core';
import { ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { InAppService } from '../..';

@Component({
  selector: 'app-universal-modal',
  templateUrl: './universal-modal.component.html',
})
export class UniversalModalComponent implements OnInit, OnDestroy {
  @Input() component: string;
  @ViewChild('template') template: TemplateRef<any>;
  incomingViewItem: any;
  incomingReizeVal: number;
  bsModalRef: BsModalRef;
  config = {
    animated: true,
    keyboard: true,
    backdrop: true,
    ignoreBackdropClick: false,
  };
  modalIsOpen = false;
  private subscriptions: Subscription[] = [];
  constructor(
    private modalService: BsModalService,
    private inAppService: InAppService
  ) {}

  ngOnInit() {
    this.subscriptions.push(
      this.inAppService.triggerUniversalModal.subscribe((data) => {
        if (data.open) {
          if (data.id) {
            this.incomingViewItem = data;
          } else if (data.closetItem) {
            this.incomingViewItem = data.closetItem;
          }
          this.incomingReizeVal = data.resizeVal;
          this.openModal(this.template);
        } else {
          this.closeModal();
        }
      })
    );
    this.subscriptions.push(
      this.inAppService.closeItemViewPopup.subscribe(() => {
        if (this.modalIsOpen) {
          this.closeModal();
        }
      })
    );
  }
  openModal(template: TemplateRef<any>) {
    this.bsModalRef = this.modalService.show(
      template,
      Object.assign({}, this.config, { class: 'wideModal' })
    );
    this.modalIsOpen = true;
  }
  closeModal() {
    this.bsModalRef.hide();
    this.modalIsOpen = false;
  }
  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }
}
