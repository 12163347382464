<!--profile cover start-->
<div class="modal-header">
  <h4 class="modal-title pull-left">{{ currentItem.designerName }}</h4>
</div>
<div class="modal-body">
  <carousel [noWrap]="true">
    <slide *ngFor="let image of currentItem.imageUrls">
      <img
        style="display: block; min-height: 400px; max-width: 250px"
        src="{{
          host +
            (!incomingItem.id
              ? localInAppService.renameImageToResized(image, resizeAmount)
              : image)
        }}"
        alt="user-img"
      />
    </slide>
  </carousel>

  <div class="row" *ngIf="!isClosetItem">
    <div class="col-6">
      <ul>
        <li>
          <label>Designer Name:</label>
          <span class="">{{ currentItem.designerName }}</span>
        </li>
        <li>
          <label>Retail Price:</label>
          <span class="">{{ currentItem.retailPrice }}</span>
        </li>
        <li>
          <label>Category:</label>
          <span class="">{{ currentItem.category }}</span>
        </li>
        <li>
          <label>Color:</label>
          <span class="">{{ currentItem.color }}</span>
        </li>
        <li>
          <label>Size:</label>
          <span class="">{{ currentItem.size }}</span>
        </li>
      </ul>
    </div>
    <div class="col-6">
      <ul>
        <li>
          <label>Condition:</label>
          <span class="">{{ currentItem.condition }}</span>
        </li>
        <li *ngIf="hasDescription">
          <label>Description:</label>
          <span class="">{{ currentItem.description }}</span>
        </li>
        <li *ngIf="hasLinkToProd">
          <label>Link To Product:</label>
          <span class="">{{ currentItem.linkToProduct }}</span>
        </li>
        <li>
          <label>Status:</label>
          <span class="">{{ currentItem.status }}</span>
        </li>
        <li>
          <label>Submitted on:</label>
          <span class="">{{
            currentItem.createdAt | date : 'dd/MM/yyyy'
          }}</span>
        </li>
      </ul>
    </div>
  </div>
  <div class="row" *ngIf="isClosetItem">
    <div class="col-12">
      <ul>
        <li>
          <label>Designer Name:</label>
          <span class="">{{ currentItem.designerName }}</span>
        </li>
        <li>
          <label>Times rented:</label>
          <span class="">{{ currentItem.rentals }}</span>
        </li>
        <li>
          <label>Favorited times:</label>
          <span class="">{{ currentItem.favorites }}</span>
        </li>
        <li>
          <label>status:</label>
          <span
            [ngClass]="{
              green: currentItem.status.toLowerCase() === 'accepted',
              yellow: currentItem.status.toLowerCase() === 'pending',
              red: currentItem.status.toLowerCase() === 'not fit'
            }"
            >{{ currentItem.status }}</span
          >
        </li>
        <li></li>
      </ul>
    </div>
  </div>
</div>
