import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NoteDialogComponent } from './components';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatDialogModule,
  ],
  declarations: [NoteDialogComponent],
  exports: [NoteDialogComponent],
})
export class NoteDialogModule {}
