<h1 mat-dialog-title>Please enter a note for the customer</h1>
<div mat-dialog-content>
  <mat-form-field formControlName="noteFormControl" style="width: 100%">
    <textarea matInput [(formControlName)]="noteFormControl"></textarea>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Cancel</button>
  <button mat-button [mat-dialog-close]="note" cdkFocusInitial>Reject</button>
</div>
