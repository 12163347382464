<!-- <div class="default-padding">
  <div class="centered-container flex-container space-around logsign">
    <div class="logsign-container">
      <div class="logsign-social">
        <div class="logsign-input fb" (click)="authFB()">
          <p>Log in with Facebook</p>
        </div>
        <div class="logsign-input google" (click)="authenticateGoogle()">
          <p>Log in with Google</p>
        </div>
      </div>
      <div class="line-breaker">or</div>
      <form (ngSubmit)="onSubmit(f, $event)" #f="ngForm" class="logsign-form">
        <app-alert-box [location]="'logsign'" class="mainAlert"></app-alert-box>
        <div class="logsign-input">
          <input name="name" type="text" class="input" placeholder="Full Name" required [(ngModel)]="fullName"
            #name="ngModel">
        </div>
        <div class="logsign-input">
          <input name="mail" type="email" class="input" placeholder="Email Address" required ngModel [pattern]="this.regexMail"
            #email="ngModel" minlength="1">
        </div>
        <div class="logsign-input">
          <input name="password" class="input" placeholder="Password" [type]="this.passType" required ngModel #pass="ngModel">
          <span class="passEye"><img src="{{currentPassEye}}" alt="noEye" (click)="togglePassEye()"></span>
        </div>
        <div class="logsign-input submit">
          <input type="submit" class="input" value="Next" [disabled]="submitting">
        </div>
      </form>
      <div class="logsign-extra flex-container space-between">
        <div class="message">Already have a D24 account? <a routerLink="/login">Sign in here</a></div>
      </div>
    </div>
    <div class="logsign-ad">
      <h1>Become a Lender.<br>It is fast and easy.</h1>
    </div>
  </div>
</div>
 -->
 <div class="centered-container flex-container space-between login-container">
  <div class="sign-in-ad">
    <div class="sign-in-message">
      <h1 class="message">Start earning<br> money in just a<br> few steps!</h1>
    </div>
  </div>
  <div class="login-form">
    <div *ngIf = "!continueEmail" class="sign-in">
        <h1 class="login-title">Create an account to get started</h1>
        <div class="logsign-social">
            <div class="logsign-input fb" (click)="authFB()">
              <p>Continue with Facebook</p>
            </div>
            <div class="logsign-input google" (click)="authenticateGoogle()">
              <p>Continue with Google</p>
            </div>
        </div>
        <div class="line-breaker">or</div>
        <div class="logsign-email">
          <div class="supplier-button solid-button" (click)="continueEmail = true">
            <!-- <i class=""></i> -->
            <a class="form-link" >Sign up with Email</a>
          </div>
        </div>
    </div>
    <div *ngIf= "continueEmail" class="sign-in">
        <h1 class="login-step2-title">Continue with <a class="facebook" (click)="continueEmail = false">Facebook</a> or <a class="google" (click)="continueEmail = false">Google</a></h1>
        <div class="line-breaker">or</div>
        <form (ngSubmit)="onSubmit(f, $event)" #f="ngForm">
            <app-alert-box [location]="'logsign'" class="mainAlert"></app-alert-box>
          <input type = "text" name ="name" class="input-wrapper" placeholder="Name" required ngModel #name = "ngModel" [(ngModel)]="fullName">
          <input type = "text" name = "mail" class="input-wrapper" placeholder="Email Address" required ngModel #email="ngModel">
          <input type = "password" name="password" class="input-wrapper" placeholder="Password" required ngModel #pass = "ngModel">
          <input type="submit" class="supplier-button solid-button" value="Sign up">
        </form>
    </div>
    <div class="line-breaker-full"></div>
    <div class="sign-up" >
      <p class="sign-up-message">Already have an account?</p>
      <div class="logsign-input new-login">
        <a class="register" routerLink="/auth/login">Log in</a>
      </div>
    </div>
  </div>
</div>
<!--
      <div class="col-sm-12 formContainer b-shadowM" id="formContainer">
          <form (ngSubmit)="onSubmit(f, $event)" #f="ngForm">
                <div class="row m-b-20">
                  <div class="col-md-12 noSidePadding">
                    <span class="f-regular noUpperDesk l-1 l-2m text-left">Become a Lender. It is fast and easy.</span>
                  </div>
                </div>
                <app-alert-box [location]="'logsign'" class="mainAlert"></app-alert-box>

                <app-alert-box
                [location]="'logsign'"
                class="mainAlert"
                [paddingClass]="'noSidePadding'"
                ></app-alert-box>

                <div class="row m-b-10">
                  <div class="col-md-6 nopaddingL noSidePaddingMob" (click)="authFB()">
                    <a style="padding-left: 48px;font-size:11px;padding-top:15px" class="btn btn-block btn-social btn-facebook fbSocial cWhite">
                      <span class="fbook"></span>
                      Continue with Facebook
                    </a>
                  </div>
                  <div class="col-md-6 nopaddingR noSidePaddingMob" (click)="authenticateGoogle()">
                    <a style="padding-left: 54px;font-size:11px;padding-top:15px" class="btn btn-block btn-social btn-google cWhite">
                      <span class="ggle gglSocial"></span>
                      Continue with Google
                    </a>
                  </div>
                </div>
                    <div class="pos-relative">
                      <input
                        name="name"
                        type="text"
                        class="formControlLanding form-control2 dynamoFont f-regular m-b-10 l-1 l-1m"
                        placeholder="Full Name"
                        required
                        ngModel
                        [pattern]="this.regexName"
                        #name="ngModel"
                      >
                      <span class="md-line"></span>
                    </div>
                    <div class="pos-relative">
                      <input
                        name="mail"
                        type="email"
                        class="formControlLanding form-control2 dynamoFont f-regular m-b-10 l-1 l-1m"
                        placeholder="Email Address"
                        required
                        ngModel
                        [pattern]="this.regexMail"
                        #email="ngModel"
                      >
                      <span class="md-line"></span>
                    </div>
                    <div class="pos-relative">
                      <input
                        name="password"
                        type="password"
                        class="formControlLanding form-control2 dynamoFont f-regular m-b-10 l-1 l-1m"
                        placeholder="Password"
                        [type]="this.passType"
                        placeholder="Password"
                        required
                        ngModel
                        #pass="ngModel"
                        minlength="6"
                        maxlength="16"
                      >
                      <span class="passEye"><img src="{{currentPassEye}}" alt="noEye" class="passEye" (click)="togglePassEye()"></span>
                      <span class="md-line"></span>
                    </div>
                <div class="row">
                  <div style="padding:0;" class="col-sm-12 col-xs-12">
                    <span class="text-inverse"><a class="customLink" (click)="goToLogin()">Already have a D24 account?</a></span>
                  </div>
                </div>
                <div class="row m-t-5">
                  <div class="col-md-12 noSidePadding">
                    <button type="submit" *ngIf="!submitting" class="btn btn-primary btn-md btn-block waves-effect text-center m-b-20">Sign up now</button>
                    <button type="button" *ngIf="submitting" disabled class="btn btn-primary btn-md btn-block waves-effect text-center m-b-20">Sign up now</button>
                  </div>
                </div>
            </form>
      </div> -->
