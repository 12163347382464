import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ResetPasswordGuard } from '../../guards';
import { ServerComService, InAppService } from '../../../../../../shared';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css'],
})
export class ResetPasswordComponent implements OnInit {
  newPassVal: string;
  confPassVal: string;

  constructor(
    private serverComService: ServerComService,
    private resetPasswordGuard: ResetPasswordGuard,
    private router: Router,
    private inAppService: InAppService,
  ) {}

  ngOnInit() {}
  checkMatch() {
    if (this.newPassVal && this.confPassVal) {
      if (this.newPassVal === this.confPassVal) {
        return true;
      }
    }
    return false;
  }
  resetPassword(e: Event) {
    e.preventDefault();
    if (this.checkMatch()) {
      const body: any = {
        token: this.resetPasswordGuard.token,
        password: this.newPassVal,
        isEncrypted: false,
        updatePassword: true,
      };
      this.serverComService.resetPassword(body).subscribe(
        (data) => {
          this.resetPasswordGuard.token = undefined;
          this.router.navigate(['admin/my-dashboard']);
        },
        (error) => {
          console.log(error);
        },
      );
    } else {
      this.inAppService.showStatusMessage.next({
        type: 'danger',
        text: 'Empty fields or passwords do not match!',
        location: 'logsign',
      });
    }
  }
}
