import { Observable, Subscription } from 'rxjs';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm, NgModel, UntypedFormControl } from '@angular/forms';
import text from '../../../../../../../assets/json/appTexts.json';
// import {
//   AuthService,
//   FacebookLoginProvider,
//   GoogleLoginProvider,
// } from 'angularx-social-login';
import {
  ServerComService,
  InAppService,
  SharedFunctionsService,
} from '../../../../../../shared';
import { environment } from '../../../../../../../environments/environment.prod';

export const FACEBOOK_CLIENT_ID = '167211943854631'; // '270135273500213' <--localhost | '167211943854631' <--live
export const GOOGLE_CLIENT_ID =
  '593375133630-9imotvlvec5kli7tl5i4068dcl0808h6.apps.googleusercontent.com';
declare const fbq: any;
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css'],
})
export class RegisterComponent implements OnInit {
  baseUrl: string = environment.EnvironmentConfig.currentCountry;
  mediaUrl: string = environment.EnvironmentConfig.mediaUrl;
  submitting = false;
  redirectUrl: string;
  formValid: boolean;
  fullName: string;
  regexMail: RegExp = new RegExp(
    // tslint:disable-next-line:max-line-length
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  regexName: RegExp = new RegExp(
    /^[a-zA-Z\u00C0-\u017F]([a-zA-Z\u00C0-\u017F.'-]+\s{1})+([a-zA-Z\u00C0-\u017F.'-]{2,})+$/i
  );
  passType: String = 'password';
  closed = true;
  currentPassEye: string =
    this.mediaUrl + 'angularapp/prodAssets/passEyeClosed.png';
  altPassEye: string = this.mediaUrl + 'angularapp/prodAssets/passEyeOpen.png';
  nosocial = false;
  safeToLeave = false;
  dataLayer: any = ((<any>window).dataLayer = (<any>window).dataLayer || []);
  currentErrorMessage = '';
  // termsAgreed: boolean = false;
  @ViewChild('f') signupForm: NgForm;
  @ViewChild('name') nameInput: NgModel;
  @ViewChild('email') emailInput: NgModel;
  @ViewChild('pass') passInput: NgModel;

  private services: Subscription[] = [];
  continueEmail: boolean;
  nextStep: boolean;
  socialPlatformProvider: string;

  constructor(
    // private authService: AuthService,
    private serviceCom: ServerComService,
    private router: Router,
    private inAppService: InAppService,
    private _functions: SharedFunctionsService
  ) {}

  ngOnInit() {
    localStorage.clear();
    this.inAppService.forceMorph.emit(true);
  }

  cleanExtraSpaces(string: string): string[] {
    const stringArr = string.split(' ');
    const removeIndexes = [];
    const returnArray = [];
    for (let i = 0; i < stringArr.length - 1; i++) {
      if (stringArr[i] === '') {
        removeIndexes.push(i);
      }
    }
    for (let i = 0; i < stringArr.length; i++) {
      if (!removeIndexes.includes(i)) {
        returnArray.push(stringArr[i]);
      }
    }
    // console.log(stringArr, returnArray);
    return returnArray;
  }

  signUp() {
    if (this.nameInput.valid) {
      const fullNameArr: string[] = this.cleanExtraSpaces(this.fullName.trim());
      const firstName: string = fullNameArr[0];
      let lastName = '';
      for (let i = 1; i < fullNameArr.length; i++) {
        i === fullNameArr.length - 1
          ? (lastName += fullNameArr[i])
          : (lastName += fullNameArr[i] + ' ');
      }
      const fullName = firstName + ' ' + lastName;
      // console.log(firstName+',', lastName+',', fullName+',');
      if (this.signupForm.valid && this.regexName.test(fullName)) {
        this.submitting = true;
        const email: string = this.emailInput.value;
        const password: string = encodeURIComponent(this.passInput.value);
        const storeId = '1';
        const body = {
          firstName: firstName,
          lastName: lastName,
          email: email,
          password: {
            value: password,
            isEncrypted: false,
          },
          storeId: storeId,
          fromSupplier: true,
          autoLogin: true,
        };
        // console.log(body);
        // let body = `firstName=${firstName}&lastName=${lastName}&email=${email}&password=${password}&fromSupplier=true&autoLogin=true`;
        this.services.push(
          this.serviceCom.signInOrUp(body, 'create').subscribe(
            (data) => {
              // console.log(data);
              if (!data[0]) {
                if (data.code && data.message) {
                  this.dataLayer.push({
                    event: 'supplierRegisterEmail',
                    outcome: 'failure',
                  });
                  this.inAppService.showStatusMessage.emit({
                    type: 'danger',
                    text: data.message,
                    location: 'logsign',
                  });
                  this.submitting = false;
                }
              } else if (data[0]) {
                this.dataLayer.push({
                  event: 'supplierRegisterEmail',
                  outcome: 'success',
                });
                fbq('track', 'CompleteRegistration', {
                  value: data[0].userInfo.email,
                });
                localStorage.setItem('currentUser', data[0].tokens.accessToken);
                this._functions.setCookie(
                  'currentUser',
                  data[0].tokens.accessToken,
                  30
                );
                localStorage.setItem('userMail', data[0].userInfo.email);

                this.router.navigate(['admin/submitted-items']);
              }
            },
            (error: Error) => {
              // console.log(error);
              this.submitting = false;
              this.dataLayer.push({
                event: 'supplierRegisterEmail',
                outcome: 'failure',
              });
              this.inAppService.showStatusMessage.emit({
                type: 'danger',
                text: error['error'].message,
                location: 'logsign',
              });
              {
                throw error;
              }
            }
          )
        );
      } else {
        this.currentErrorMessage = text.logsign.register.errors.noName;
        this.inAppService.showStatusMessage.emit({
          type: 'danger',
          text: this.currentErrorMessage,
          location: 'logsign',
        });
      }
    }
  }

  authFB() {
    // this.socialPlatformProvider = FacebookLoginProvider.PROVIDER_ID;
    // this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
    // this.authService.authState.subscribe((user) => {
    //   this.serviceCom.loginFacebook(user).subscribe(
    //     (data) => {
    //       const response = data;
    //       // console.log(response)
    //       if (response[0].userInfo && response[0].userInfo.email === '') {
    //         this.dataLayer.push({
    //           event: 'supplierRegisterFacebook',
    //           outcome: 'no email success',
    //         });
    //         this.nosocial = true;
    //         if (!data.image) {
    //           if (data.social && data.social.facebook) {
    //             localStorage.setItem(
    //               'socialImageSmall',
    //               data.social.facebook.picture.small
    //             );
    //             localStorage.setItem(
    //               'socialImageLarge',
    //               data.social.facebook.picture.large
    //             );
    //           }
    //         }
    //         localStorage.setItem('currentUser', response[0].tokens.accessToken);
    //         this._functions.setCookie(
    //           'currentUser',
    //           response[0].tokens.accessToken,
    //           30
    //         );
    //         localStorage.setItem('userMail', response[0].userInfo.email);
    //         this.router.navigate(['auth/nosocialmail']);
    //       } else {
    //         localStorage.setItem('currentUser', response[0].tokens.accessToken);
    //         this._functions.setCookie(
    //           'currentUser',
    //           response[0].tokens.accessToken,
    //           30
    //         );
    //         localStorage.setItem('userMail', response[0].userInfo.email);
    //         this.router.navigate(['admin/submitted-items']);
    //         this.dataLayer.push({
    //           event: 'supplierRegisterFacebook',
    //           outcome: 'success',
    //         });
    //       }
    //     },
    //     (error) => {
    //       this.dataLayer.push({
    //         event: 'supplierRegisterFacebook',
    //         outcome: 'failure',
    //       });
    //       this.inAppService.showStatusMessage.next({
    //         type: 'danger',
    //         text: 'it seems something went wrong, please refresh and try again.',
    //         location: 'logsign',
    //       });
    //     }
    //   );
    // });
  }

  authenticateGoogle() {
    // this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
    // this.authService.authState.subscribe((user) => {
    //   this.serviceCom.loginGoogle(user).subscribe(
    //     (data) => {
    //       localStorage.setItem('currentUser', data[0].tokens.accessToken);
    //       this._functions.setCookie(
    //         'currentUser',
    //         data[0].tokens.accessToken,
    //         30
    //       );
    //       localStorage.setItem('userMail', data[0].userInfo.email);
    //       this.dataLayer.push({
    //         event: 'supplierLoginGoogle',
    //         outcome: 'success',
    //       });
    //       this.router.navigate(['admin/submitted-items']);
    //       this.dataLayer.push({
    //         event: 'supplierRegisterGoogle',
    //         outcome: 'success',
    //       });
    //     },
    //     (error) => {
    //       this.dataLayer.push({
    //         event: 'supplierRegisterGoogle',
    //         outcome: 'failure',
    //       });
    //       this.inAppService.showStatusMessage.next({
    //         type: 'danger',
    //         text: 'it seems something went wrong, please refresh and try again.',
    //         location: 'logsign',
    //       });
    //       // console.log("ERROR:" + JSON.stringify(error));
    //     }
    //   );
    // });
  }
  togglePassEye() {
    this.closed = !this.closed;
    if (!this.closed) {
      this.passType = 'text';
    } else {
      this.passType = 'password';
    }
    const temp = this.currentPassEye;
    this.currentPassEye = this.altPassEye;
    this.altPassEye = temp;
  }
  onSubmit(form: NgForm, event: Event) {
    (form.controls['password'] as UntypedFormControl).markAsDirty();
    (form.controls['name'] as UntypedFormControl).markAsDirty();
    (form.controls['mail'] as UntypedFormControl).markAsDirty();
    if ((form.controls['name'] as UntypedFormControl).invalid) {
      this.currentErrorMessage = text.logsign.register.errors.noName;
    } else if ((form.controls['mail'] as UntypedFormControl).invalid) {
      this.currentErrorMessage = text.logsign.register.errors.noMail;
    } else if ((form.controls['password'] as UntypedFormControl).invalid) {
      this.currentErrorMessage = text.logsign.register.errors.noPass;
    }
    // this.validateCheckbox();
    this.signUp();
  }
  goToLogin() {
    this.safeToLeave = true;
    this.router.navigate(['auth/login']);
  }
}
