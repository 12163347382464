import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ServerComService, InAppService } from '../../../../../../shared';

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.css'],
})
export class ForgotComponent implements OnInit {
  @ViewChild('f') form: NgForm;
  regexMail: RegExp = new RegExp(
    // tslint:disable-next-line:max-line-length
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  emailValue = '';
  constructor(
    private serverComService: ServerComService,
    private inAppService: InAppService
  ) {}

  ngOnInit() {}
  onSubmit(form: NgForm, event) {
    this.resetPass();
  }
  resetPass() {
    if (this.form.valid) {
      const email: string = this.emailValue;
      const body: any = {
        email: email,
        rUrl: 'supplier.designer-24.com/auth/resetPassword', // 'https://supplier.designer-24.com/'
        storeId: 1,
      };
      this.serverComService.sendEmailForResetPassword(body).subscribe(
        (data) => {
          if (data.status) {
            // this.errorMsg=data[1];
            this.inAppService.showStatusMessage.next({
              type: 'success',
              text:
                'An email has been send to "' +
                body.email +
                '", Please reset your password from there',
              location: 'logsign',
            });
          } else {
            // this.alertType = 'success';
            // this.subTitleText = data[1];
            this.inAppService.showStatusMessage.next({
              type: 'success',
              text: 'Either something went wrong or the email you provided is not a valid user',
              location: 'logsign',
            });
          }
          // //console.log('data: '+ data);
        },
        (error) => {
          {
            throw error;
          }
        },
        () => {}
      );
    } else {
      // this.errorMsg envStringsstrings.logsign.errorMsgs[0];;
      this.inAppService.showStatusMessage.next({
        type: 'danger',
        text: 'please make sure you entered the email address correctly',
        location: 'logsign',
      });
    }
  }
}
