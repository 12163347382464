import { Router } from '@angular/router';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { envStrings } from '../../../../../../../environments/envStrings';
import { ServerComService, InAppService } from '../../../../../../shared';

@Component({
  selector: 'app-nosocial',
  templateUrl: './nosocial.component.html',
  styleUrls: ['./nosocial.component.css'],
})
export class NosocialComponent implements OnInit, AfterViewInit {
  @ViewChild('f') form: NgForm;
  regexMail: RegExp = new RegExp(
    // tslint:disable-next-line:max-line-length
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  emailValue = '';
  constructor(
    private serverCom: ServerComService,
    private inAppService: InAppService,
    private router: Router
  ) {}

  ngOnInit() {}
  ngAfterViewInit() {
    this.inAppService.showStatusMessage.next({
      type: 'warning',
      text:
        "Your Facebook account doesn't seem to have an email address associated with it." +
        'Please provide an email address to login with your facebook account.',
      location: 'logsign',
    });
  }
  onSubmit(form: NgForm, event) {
    if (form.valid) {
      this.mergeMail();
    } else {
      this.inAppService.showStatusMessage.next({
        type: 'danger',
        text: envStrings.strings.logsign.subtitles[0],
        location: 'logsign',
      });
    }
  }
  mergeMail() {
    const email: string = this.emailValue;
    localStorage.setItem('userMail', email);
    const token = localStorage.getItem('currentUser');
    const body = `email=${email}&token=${token}`;
    this.serverCom.addFacebookMail(body).subscribe(
      (data) => {
        if (!data[0]) {
          this.inAppService.showStatusMessage.next({
            type: 'danger',
            text: data[1],
            location: 'logsign',
          });
          if (data[2] !== undefined && data[2]) {
            this.inAppService.storableStatusMessage = {
              type: 'warning',
              text: envStrings.strings.logsign.subtitles[7],
              location: 'logsign',
            };

            this.router.navigate(['auth/login']);
          }
        } else if (data[0]) {
          // this.dataLayer.push({
          //   'event': 'registerFacebook',
          //   'outcome' : 'with email success'
          // });
          this.serverCom.getSupplierInfo().subscribe(
            // tslint:disable-next-line:no-shadowed-variable
            (data) => {
              if (
                data.supplier.isSupplier &&
                data.supplier.isSupplier === true &&
                data.supplier.phone
              ) {
                this.router.navigate(['admin/submitted-items']);
              } else {
                this.router.navigate(['auth/additional']);
              }
            },
            (e) => {}
          );
        }
      },
      (error) => {
        {
          throw error;
        }
      }
    );
  }
}
