import { Observable, throwError } from 'rxjs';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, ActivatedRoute } from '@angular/router';
import { Injectable } from '@angular/core';
import { ServerComService, InAppService } from '../../../../../shared';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class ResetPasswordGuard  {
  constructor(
    private serverCom: ServerComService,
    private router: Router,
    private inAppService: InAppService
  ) {}

  token: string;
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const token = this.inAppService.getParameterByName('token');
    this.token = token;
    return this.serverCom
      .resetPassword({ token: token, updatePassword: false })
      .pipe(
        map((data) => {
          if (data.status) {
            return true;
          } else {
            this.router.navigate(['/']);
          }
        }),
        catchError((error) => {
          localStorage.clear();
          this.router.navigate(['/']);
          return throwError(error);
        })
      );
  }
}
