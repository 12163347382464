<div *ngIf="showStatusText && !isNew" class="row">
  <div class="col-12 {{paddingClass}}">
    <alert [type]="statusType">
          <span *ngIf="!this.linkInText" class="alertText">{{statusText}}</span>
          <span *ngIf="this.linkInText" class="alertText">{{statusText1}} <a class="customLink underline" routerLink="/{{linkDest}}">{{linkInText}}</a> {{statusText2}}</span>
          <div (click)="hideMe()" class="invisClick pointer"></div>
    </alert>
  </div>
</div>

<div *ngIf="showStatusText && isNew" class="row">
  <div class="col-12 {{paddingClass}}">
    <div class="announcementMessageContainer">
        <span class="typeIndicator pink">{{spanTitle}}</span>
        <span *ngIf="!this.linkInText" class="titleText">{{title}}</span>
        <span *ngIf="!this.linkInText" class="alertText">{{statusText}}</span>
        <span *ngIf="this.linkInText" class="alertText">{{statusText1}} <a class="customLink underline" routerLink="/{{linkDest}}">{{linkInText}}</a> {{statusText2}}</span>
        <span (click)="hideMe()" class="removeXContainerTrans">
            <span class="removeX"></span>
        </span>
    </div>
  </div>
</div>