import { Component, OnInit, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { environment } from '../../../../../../environments/environment.prod';
import { InAppService } from '../../../../services';
@Component({
  selector: 'app-alert-box',
  templateUrl: './alert-box.component.html',
  styleUrls: ['./alert-box.component.css'],
})
export class AlertBoxComponent implements OnInit {
  statusType: string;
  statusText: string;
  statusText1: string;
  statusText2: string;
  mediaUrl: string = environment.EnvironmentConfig.mediaUrl;
  icon: string;
  iconCancel: string;
  spanTitle;
  showStatusText = false;
  @Input() paddingClass;
  linkDest: string;
  linkInText: string;
  @Input() location: string;
  @Input() isNew: boolean;
  title: string;

  private services: Subscription[] = [];
  constructor(private dataEmitService: InAppService) {}

  ngOnInit() {
    this.services.push(
      this.dataEmitService.showStatusMessage.subscribe((statusObject:any) => {
        if (statusObject.location === this.location) {
          this.title = statusObject.title;
          this.spanTitle = statusObject.spanTitle;
          this.statusType = statusObject.type;
          if (
            this.statusType === 'success' ||
            this.statusType === 'checkout-main'
          ) {
            this.icon =
              this.mediaUrl + 'angularapp/prodAssets/success-icon.png';
          } else if (this.statusType === 'warning') {
            this.icon =
              this.mediaUrl + 'angularapp/prodAssets/warning-icon.png';
          } else {
            this.icon = this.mediaUrl + 'angularapp/prodAssets/error-icon.png';
          }
          this.statusText = statusObject.text;
          this.showStatusText = true;
          if (statusObject.timer) {
            setTimeout(() => {
              this.showStatusText = false;
            }, statusObject.timer * 1000);
          }
          if (statusObject.linkInText) {
            const text: string = statusObject.text;
            const index = text.indexOf('linkInText');
            this.statusText1 = text.substr(0, 57);
            this.statusText2 = text.substr(57, text.length);
            this.linkDest = statusObject.linkDest;
            this.linkInText = statusObject.linkInText;
          }
          if (statusObject.hide) {
            this.showStatusText = false;
          }
        }
      }),
    );
  }
  hideMe() {
    this.showStatusText = false;
  }
}
