import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { register } from 'swiper/element/bundle';
import { LoaderComponent } from './core/components';

register();
@NgModule({
  declarations: [AppComponent, LoaderComponent],
  imports: [BrowserModule, CoreModule, BrowserAnimationsModule],
  bootstrap: [AppComponent],
})
export class AppModule {}
