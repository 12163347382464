import { Injectable, OnInit } from '@angular/core';
import { PhoneNumberUtil } from 'google-libphonenumber';

@Injectable({providedIn: 'root'})
export class PhoneNumberValidationService {
  countryCodes: number[] = [];

  constructor() {
    const phoneNumberUtil = PhoneNumberUtil.getInstance();
    const countries: string[] = phoneNumberUtil.getSupportedRegions();
    this.countryCodes.push(90);
    countries.forEach((country) => {
      const countryCode = phoneNumberUtil.getCountryCodeForRegion(country);
      if (this.countryCodes.indexOf(countryCode) === -1) {
        this.countryCodes.push(countryCode);
      }
    });
    this.countryCodes.sort((a, b) => (a > b ? 1 : -1));
  }

  isValidCountryCode(code: number): boolean {
    return this.countryCodes.includes(code);
  }

  isValidNumber(code: string, phone: string): boolean {
    try {
      const phoneNumberUtil = PhoneNumberUtil.getInstance();
      const phoneNumber = phoneNumberUtil.parse('+' + code + phone);
      return phoneNumberUtil.isValidNumber(phoneNumber);
    } catch {
      return false;
    }
  }
}
