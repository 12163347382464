import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment.prod';


@Injectable({ providedIn: 'root' })
export class UploadService {
  constructor(private http: HttpClient) {}

  uploadImage(formData: FormData, fileName) {
    return this.http
      .post<any>(
        environment.EnvironmentConfig.backendHost +
          `supplier/info?filename=${fileName}`,
        formData
      )
      
  }
}
