export const envStrings = {
  production: true,
  storedJSONs: {
    countries: [
      {
        countryName: 'US',
        sizes: ['00', '0', '2', '4', '6', '8', '10', '12', 'N/A'],
        shoeSize: ['5', '6', '7', '8', '9', '10', '11'],
      },
      {
        countryName: 'UK',
        sizes: ['4', '6', '8', '10', '12', '14', '16', 'N/A'],
        shoeSize: ['3', '4', '5', '6', '7', '8', '9'],
      },
      {
        countryName: 'Italy',
        sizes: ['36', '38', '40', '42', '44', '46', '48', 'N/A'],
        shoeSize: ['36', '37', '38', '39', '40', '41', '42'],
      },
      {
        countryName: 'France',
        sizes: ['32', '34', '36', '38', '40', '42', '44', 'N/A'],
        shoeSize: ['36', '37', '38', '39', '40', '41', '42'],
      },
      {
        countryName: 'International',
        sizes: ['XXS', 'XS', 'S', 'M', 'L', 'XL', 'XXL', 'OS', 'N/A'],
        shoeSize: ['36', '37', '38', '39', '40', '41', '42'],
      },
    ],
    countryNames: [
      'US',
      'UK',
      'Italy',
      'France',
      'Denmark',
      'Russia',
      'Germany',
      'Australia',
      'Japan',
      'Other',
    ],
    standardErrorMessages: [
      'Please make sure you have entered your info correctly',
    ],
    stores: ['Lebanon', 'UAE'],
    categories: [
      {
        label: 'Wedding Dress',
        imageWidth: '95px',
        value: 'bridal-gown',
        image:
          'https://cdn.designer-24.com/angularapp/prodAssets/portalImages/images/wedding-dress.png',
        bgColor: '#f3e9ed',
        parentCategory: 'bridal',
        market: ['2', '1'],
      },
      {
        label: 'Head Pieces',
        imageWidth: '130px',
        value: 'head-pieces',
        image:
          'https://cdn.designer-24.com/angularapp/prodAssets/portalImages/images/head-pieces.png',
        bgColor: '#f8e6fb',
        parentCategory: 'bridal',
        market: ['1', '2'],
      },
      {
        label: 'Dresses',
        imageWidth: '95px',
        value: 'dresses',
        image:
          'https://cdn.designer-24.com/angularapp/prodAssets/portalImages/images/edress.png',
        bgColor: '#fff2f7',
        parentCategory: 'clothing',
        market: ['2', '1'],
      },
      {
        label: 'Jackets',
        imageWidth: '95px',
        value: 'jackets',
        image:
          'https://cdn.designer-24.com/angularapp/prodAssets/portalImages/images/jacket.png',
        bgColor: '#ffe3e3',
        parentCategory: 'clothing',
        market: ['1'],
      },
      {
        label: 'Jumpsuits',
        imageWidth: '60px',
        value: 'jumpsuits',
        image:
          'https://cdn.designer-24.com/angularapp/prodAssets/portalImages/images/jumpsuit.png',
        bgColor: '#fff2e7',
        parentCategory: 'clothing',
        market: ['2', '1'],
      },
      {
        label: 'Kaftans',
        imageWidth: '60px',
        value: 'kaftans',
        image:
          'https://cdn.designer-24.com/angularapp/prodAssets/portalImages/images/kaftan.png',
        bgColor: '#f9fff3',
        parentCategory: 'clothing',
        market: ['1'],
      },
      {
        label: 'Bags',
        imageWidth: '95px',
        value: 'bags',
        image:
          'https://cdn.designer-24.com/angularapp/prodAssets/portalImages/images/bags.png',
        bgColor: '#e6f0fb',
        parentCategory: 'accessories',
        market: ['1', '2'],
      },
      {
        label: 'Shoes',
        imageWidth: '130px',
        value: 'shoes',
        image:
          'https://cdn.designer-24.com/angularapp/prodAssets/portalImages/images/p-heels.png',
        bgColor: '#fbe1ec',
        parentCategory: 'accessories',
        market: ['1', '2'],
      },
      {
        label: 'Hats',
        imageWidth: '95px',
        value: 'hats',
        image:
          'https://cdn.designer-24.com/angularapp/prodAssets/portalImages/images/hat.png',
        bgColor: '#fffeec',
        parentCategory: 'accessories',
        market: ['1'],
      },
      {
        label: 'Jewelry',
        imageWidth: '95px',
        value: 'jewelry',
        image:
          'https://cdn.designer-24.com/angularapp/prodAssets/portalImages/images/jewelry.png',
        bgColor: '#ebfffa',
        parentCategory: 'accessories',
        market: ['1'],
      },
    ],
  },
  strings: {
    alertbox: {},
    cart: {
      cartItem: {
        titleText: 'SHOPPING CART',
        warningMessages: {
          rsrvOrOut0:
            'Some of your items were removed from your cart due to being reserved or outdated.',
          rsrv0:
            'Some of your items were removed from your cart due to being reserved. You can find them in your saved for later list below.',
          // tslint:disable-next-line:max-line-length
          out0: 'Some of your items were removed from your cart due to being outdated. You can find them in your saved for later list below.',
          reserved0:
            'Some items got removed from your cart as they have already' +
            'been booked on the dates you specified. You can find them ' +
            'in your saved for later list below.',
          outdated0:
            'Some items got removed from your cart as the date you ' +
            'have selected for them has passed. ' +
            'You can find them in your saved for later list below.',
          specific: {
            segment0: "The accessory '",
            segment0Alt: "The dress '",
          },
          specificRsrv:
            "' got removed from your cart as it has already " +
            'been booked on the dates you specified. ' +
            'You can find it in your saved for later list below.',

          specificOut:
            "' got removed from your cart as the date you " +
            'have selected for it has passed. ' +
            'You can find it in your saved for later list below.',
        },
      },
      emptyBox: {
        boxTitle1: 'YOUR SHOPPING CART IS EMPTY',
        boxTitle2: 'YOUR SAVED FOR LATER IS EMPTY',
        boxMsg1: 'You may have items in your saved for later.',
        boxMsg2: 'You may have items in your shopping cart',
      },
      itemDetails: {
        bookedTimesMsg1: 'Trending now!',
        bookedTimesMsg2: 'In high demand!',
        bookNowMsg:
          'people are viewing this product, rent it while you still can',
        sflMsg:
          'You have to be logged in to move this item to your saved for later list',
        buttons: [
          'Save for later',
          'Delete',
          'Login?',
          'Deleting',
          'Saving',
          'Book',
        ],
        labels: ['Size:', 'From:', 'To:', 'Try on date:'],
      },
      savedForLater: {
        continueShopping: 'CONTINUE SHOPPING',
        titleText: 'SAVED FOR LATER',
      },
    },

    cartSummaryBox: {
      title: 'TOTAL',
      couponPost: [
        'You are now booking our try on service, you can try up to ',
        ' styles with ',
        ' back-up sizes (if available) for Free.',
      ],
      cpnApplied0: 'The Coupon Code ',
      cpnApplied1: ' was applied.',
      buttons: ['PROCEED TO CHECKOUT', 'ADD COUPON'],
      labels: ['SUBTOTAL:', 'DELIVERY FEE:', 'DISCOUNT:', 'We Accept:'],
    },
    bankForm: {
      buttons: ['SUBMIT BANK INFO'],
    },
    checkout: {
      checkoutStage: {
        successMsg: ', enjoy a FREE delivery on your order, on us!',

        noOptionsMsg: [
          'You do not have any saved ',
          'Add a new one by clicking the link below',
        ],

        walletMsgs: {
          useWalletFirst: 'Use my wallet first: ',
          walletCoversAll: 'Your wallet will cover the whole amount',
          walletCoversPartial: [
            'Your wallet will cover ',
            ', of the amount, the rest ',
            ' will be expected from you upon reception of your first item',
            ' will be charged from your card upon placing the order',
          ],
          noCash:
            "We're sorry but items booked for later than 2 weeks can not be payed through cash on delivery",
          expectedAmount: [
            'The below amount will be expected from you upon reception of',
            'your first item',
          ],
        },

        warning: [
          "We're sorry but D24+ is a try on service not available for showroom pick up",
          'You do not have any saved addresses, you must have at least one address saved to proceed with "Pickup" method',
        ],

        error: [
          'Please make sure you have correclty selected a shipping option',
          'Please make sure you have correclty selected a payment method',
          'Something went wrong, please try again.',
        ],

        tinyText: ['addresses', 'cards', 'Choose an address'],

        labels: ['Delivery Charge', 'DELIVERY', 'CARD', 'PICKUP', 'CASH'],

        buttons: [
          'NEXT',
          'PREVIOUS',
          'PLACE ORDER',
          'View in Google Maps',
          'Add Address',
          'Add Card',
          'Manage Addresses',
          'Manage Cards',
          'Done Managing',
        ],
      },
      addressForm: {
        placeholders: [
          'Address nickname. E.g: Home, Work',
          'Street name, Area, Bldg, Floor, Apt #',
          'Mobile',
          'Additional Notes | eg. 2nd door to the right',
          'Code',
        ],
        buttons: ['ADD ADDRESS', 'CANCEL', 'UPDATE ADDRESS'],
        messages: [
          'The address ',
          ' was successfully added',
          ' was successfully updated',
        ],
        error: [
          'There was a problem processing your request, please refresh and try again',
          'Oops, you still have some fields which are empty or incorrect',
        ],
        tinyText: ['Country', 'City', 'Make default'],
      },
      cardForm: {
        placeholders: [
          'Card nickname. E.g: My primary card',
          'Credit card number',
          'Name on card',
        ],
        buttons: [
          'ADD CARD',
          'CANCEL',
          'UPDATE CARD',
          'UPDATING CARD',
          'ADDING CARD',
        ],
        messages: [
          'The card ',
          ' was successfully added',
          ' was successfully updated',
        ],
        error: [
          'There was a problem processing your request, please refresh and try again',
          'Invalid card info! please review your information and try again',
          'Oops, you still have some fields which are empty or incorrect',
        ],
        tinyText: ['Country', 'City', 'Make default'],
      },
      success: {
        title: 'ORDER CONFIRMED',
        text: [
          'Your Order ',
          ' has been successfully processed.',
          'Thank you for placing an order with Designer24, we look forward to dressing you up!',
          '(Make sure you have popups enabled)',
        ],
        buttons: ['Download Receipt', 'CONTINUE SHOPPING'],
        messages: [
          'Your Receipt is still being prepared...Kindly try again in a few seconds',
          'We couldnt retrieve your receipt at this time, please check your email to find it there',
        ],
      },
    },
    logsign: {
      errorMsgs: [
        'Please make sure you have no incorrect or empty fields',
        'Your Social Login failed. Please try again.',
      ],
      subtitles: [
        'Please sign in to proceed to checkout.',
        'Please sign up to proceed to checkout.',
        'Please enter your registered email address & we will send you a link to reset your password right away!',
        'Your password has been successfully reset.',
        'The Coupon Code ',
        ' requires you to be signed in',
        'Your facebook account is set up to not share your email.\n' +
          'Please provide an email address to login.',
        'This email already exists please log in using your password.',
      ],
      alternateText: [
        'Forgot password?',
        'Dont have an account?',
        'Take me back to ',
        'Already have an account?',
        'Not yet a member? Click here to ',
      ],
    },
    popup: {
      payment:
        // tslint:disable-next-line:max-line-length
        'If you have earned money through our services, you can request a payment and we will transfer your earnings directly into your bank account. Please note that you can only request the payment of earnings dating up until the past two months (e.g if today is in April, you can request the earnings you’ve collected up until the beginning of Feb). Furthermore, this procedure will likely take up to 30 business days.',
      wallet:
        // tslint:disable-next-line:max-line-length
        'Liked something in our collection? Transfer some of your earnings to your wallet and rent out any item you wish! Renting out new clothes by renting out your old ones! This is your chance to experience the unlimited closet experience, what are you waiting for!',
    },
  },
  transitionHeight: 125,
};
