<ng-template #template>
  <button type="button" class="myCustomX close" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
  </button>
  <app-view-item
  [incomingItem]="incomingViewItem"
  [resizeAmount]="incomingReizeVal"
  ></app-view-item>
</ng-template>

