
<div class="centered-container flex-container space-between login-container">
    <div class="sign-in-ad">
      <div class="sign-in-message">
        <h1 class="message">Start earning<br> money in just a<br> few steps!</h1>
      </div>
    </div>
    <div class="login-form">
      <div class="nosocial-container">
        <h1 class="nosocial-title">Hello Facebook user.</h1>
        <app-alert-box
          [location]="'logsign'"
          class="mainAlert"
        ></app-alert-box>
        <form (ngSubmit)="onSubmit(f, $event)" #f="ngForm">
          <input
          name="mail"
          type="text"
          class="input-wrapper"
          placeholder="Email Address"
          required
          [(ngModel)]="emailValue"
          [pattern]="this.regexMail"
          #email="ngModel"
          >
          <input type="submit" class="supplier-button solid-button" value="Add Email">
        </form>
      </div>
      <div class="line-breaker-full"></div>
      <div class="backTo-sign-in-up">
        <a [routerLink]="['auth/register']" class="customLink nosocial-signUp">New to D24? Sign up here</a>
        <a [routerLink]="['auth/login']" class="customLink nosocial-back-login"> Back to login?</a>
      </div>
    </div>
</div>
