import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { InAppService } from '../../shared';
import { catchError } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private injector: Injector, private router: Router) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const inAppService = this.injector.get(InAppService);
    const authHeader = new HttpHeaders().set(
      'Authorization',
      'Bearer ' + localStorage.getItem('currentUser')
    );
    authHeader.append('Content-Type', 'application/json');

    const formHeader = new HttpHeaders().set(
      'Content-Type',
      'application/x-www-form-urlencoded'
    );

    const normalHeader = new HttpHeaders().set(
      'Content-Type',
      'application/json'
    );

    const plainHeader = new HttpHeaders().set(
      'Access-Control-Allow-Origin',
      '<origin>'
    );

    const fileUploadHeader = new HttpHeaders().set(
      'Authorization',
      'Bearer ' + localStorage.getItem('currentUser')
    );
    fileUploadHeader.append('Content-Type', 'multipart/form-data');
    fileUploadHeader.append('Accept', 'application/json');

    let requestClone;
    const isMageApi: boolean = request.url.includes('d24public');
    const formNeeded: boolean = request.url.includes('/user/');
    const isFileUpload: boolean = request.url.includes('/supplier/info');
    const isBankCall: boolean = request.url.includes('bank.codes');

    if (
      request.url.includes('listing') &&
      !localStorage.getItem('currentUser')
    ) {
      requestClone = request.clone({
        headers: normalHeader,
      });
    } else if (isMageApi && !formNeeded) {
      requestClone = request.clone();
    } else if (isMageApi && formNeeded) {
      requestClone = request.clone({
        headers: formHeader,
      });
    } else if (request.url.includes('teams/report')) {
      requestClone = request.clone({
        headers: normalHeader,
      });
    } else if (isFileUpload) {
      requestClone = request.clone({
        headers: fileUploadHeader,
      });
      inAppService.uploadRequest.next(request);
    } else if (isBankCall) {
      requestClone = request.clone({
        headers: plainHeader,
      });
    } else {
      requestClone = request.clone({
        headers: authHeader,
      });
    }
    const finalRequest = requestClone;
    return next.handle(finalRequest).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error instanceof HttpErrorResponse) {
          if ((<HttpErrorResponse>error).status === 403) {
            localStorage.clear();
            return throwError(this.router.navigate(['auth/login']));
          } else if ((<HttpErrorResponse>error).status === 413) {
            inAppService.triggerUploadErrorModalAddItem.next();
          } else {
            return throwError(<HttpErrorResponse>error);
          }
        }
      })
    );
  }
  errorHandler(error: any): void {
    console.log(error);
  }
}
