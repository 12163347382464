import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard, PreAuthGuard } from './guards';

const routes: Routes = [
  {
    path: '',
    data: { title: 'Pre Auth' },
    canActivate: [PreAuthGuard],
    loadChildren: () =>
      import('../modules/pre-auth/pre-auth.module').then(
        (m) => m.PreAuthModule
      ),
  },
  {
    path: 'admin',
    data: { title: 'Admin' },
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../modules/main/main.module').then((m) => m.MainModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class CoreRoutingModule {}
