import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { NgForm, UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { envStrings } from '../../../../../../../environments/envStrings';
import { ServerComService, InAppService, PhoneNumberValidationService } from '../../../../../../shared';
import text from '../../../../../../../assets/json/appTexts.json';
@Component({
  selector: 'app-additional-info',
  templateUrl: './additional-info.component.html',
})
export class AdditionalInfoComponent implements OnInit, OnDestroy {
  dataLayer: any = ((<any>window).dataLayer = (<any>window).dataLayer || []);
  placeHolders: string[] = envStrings.strings.checkout.addressForm.placeholders;
  userMail: string = localStorage.getItem('userMail')
    ? localStorage.getItem('userMail')
    : '';
  socialLinks = '';
  website = '';
  company = '';
  selectedStore: any;
  mobileValue = '';
  mobileCode = '';
  currentTag = '';
  refNum = '';
  allTags: string[] = [];
  phoneValid = false;
  designsOwn = false;
  currentTagNumber = 0;
  stores: string[] = envStrings.storedJSONs.stores;
  chosenStore: string;
  tags: any;
  mobileCodeWidth = 'col-2';
  mobileValueWidth = 'col-10';

  currencyList: Array<any> = [];
  chosenCurrency: string;
  organisation = ['Regular', 'Shop/Boutique', 'Designer', 'Company'];
  organisationType: string;
  currentType: string;
  nextStep = false;

  // acceptTerms: boolean = false;
  @ViewChild('f') form: NgForm;
  constructor(
    private router: Router,
    private serverCom: ServerComService,
    private inAppService: InAppService,
    private phoneValidationService: PhoneNumberValidationService
  ) {}

  ngOnInit() {
    this.checkSizes();
    this.currencyList = [
      {
        value: 'AED',
        label: 'AED',
      },
      {
        value: 'USD',
        label: 'USD',
      },
    ];
    this.inAppService.hideSignOptions.emit(true);
  }
  ngOnDestroy() {
    this.inAppService.hideSignOptions.emit(false);
  }
  callType(event: any) {
    this.organisationType = event.target.value;
  }
  checkSizes() {
    if (document.documentElement.clientWidth < 350) {
      this.mobileCodeWidth = 'col-4';
      this.mobileValueWidth = 'col-8';
    } else if (document.documentElement.clientWidth < 768) {
      this.mobileCodeWidth = 'col-3';
      this.mobileValueWidth = 'col-9';
    } else if (document.documentElement.clientWidth >= 990) {
      this.mobileCodeWidth = 'col-2';
      this.mobileValueWidth = 'col-10';
    }
  }
  onResize(event) {
    this.checkSizes();
  }
  validateCode() {
    const input = <HTMLInputElement>document.getElementById('valCodeInput');
    if (this.phoneValidationService.isValidCountryCode(parseInt(this.mobileCode, 10))) {
      input.className = 'input ng-dirty ng-valid ng-touched';
    } else {
      input.className = 'input ng-dirty ng-invalid ng-touched';
    }

    if (this.mobileValue) {
      this.validateNumber();
    }
  }
  validateNumber() {
    const input = <HTMLInputElement>document.getElementById('valNumInput');
    if (
      this.phoneValidationService.isValidNumber(
        this.mobileCode,
        this.mobileValue
      )
    ) {
      input.className = 'input ng-dirty ng-valid ng-touched';
      this.phoneValid = true;
    } else {
      this.phoneValid = false;
      input.className = 'input ng-dirty ng-invalid ng-touched';
    }
  }
  tagsToString() {
    let commaSeparatedTags = '';
    for (let i = 0; i < this.tags.length; i++) {
      if (i < this.tags.length - 1) {
        commaSeparatedTags += this.tags[i]['value'] + ', ';
      } else {
        commaSeparatedTags += this.tags[i]['value'];
      }
    }
    // //console.log(this.socialLinks, commaSeparatedTags)
    if (this.socialLinks !== commaSeparatedTags) {
      this.socialLinks = commaSeparatedTags;
    }
    return commaSeparatedTags;
  }
  additionalInfo() {
    if (this.form.valid && this.phoneValid) {
      // let commaSeparatedTags = this.tagsToString();
      const body: any = {
        phone: this.mobileCode + '' + this.mobileValue,
        code: this.mobileCode + '',
        isSupplier: true,
        fromAdditionalInfo: true,
        supplierInfo: {
          oragnisationType: this.organisationType,
          company: this.company,
          designer: this.designsOwn ? 'yes' : 'no',
          socialMediaLinks: this.tags,
          website: this.website,
          referedBy: this.refNum,
          preferredCurrency: (<any>this.chosenCurrency).label,
          source: 'portal',
        },
      };
      // console.log(body);
      this.serverCom.putSupplierInfo(body).subscribe(
        (data) => {
          this.dataLayer.push({
            event: 'becomeASupplier',
            reference: this.refNum,
            outcome: 'success',
          });
          // console.log(data[0]);
          this.inAppService.storedSupplierInfo = data[0];

          this.inAppService.isSupplier = true;
          this.inAppService.storedPhoneObject.code = this.mobileCode;
          this.inAppService.storedPhoneObject.phone = this.mobileValue;
          this.router.navigate(['auth/phoneVerify']);
        },
        (e) => {
          this.dataLayer.push({
            event: 'becomeASupplier',
            outcome: 'fail',
          });
          this.inAppService.showStatusMessage.emit({
            type: 'danger',
            text: 'There seems to be an issue with the internet connection. Please refresh and try again',
            location: 'additional',
          });
          // console.log(e)
        }
      );
    } else {
      this.showErrorMsg();
    }
  }
  showErrorMsg() {
    if (!this.chosenCurrency) {
      this.inAppService.showStatusMessage.emit({
        type: 'danger',
        text: 'Please Choose a preferred currency',
        location: 'additional',
      });
    } else {
      this.inAppService.showStatusMessage.emit({
        type: 'danger',
        text: text.additional.errors.noPhone,
        location: 'additional',
      });
    }
  }
  selectStore(selectedStore: any) {
    this.selectedStore = selectedStore;
  }
  addUnTagged() {
    const inputs = <HTMLCollectionOf<HTMLInputElement>>(
      document.getElementsByClassName('ng2-tag-input__text-input')
    );
    // console.log('inputss',inputs)
    const input = <HTMLInputElement>inputs[0];
    if (input.value.length > 0) {
      this.tags.push({ value: input.value, display: input.value });
      input.value = '';
    }
  }
  exitAdditional() {
    localStorage.clear();
    this.inAppService.isSupplier = false;
    this.inAppService.phoneVerfied = false;
    this.router.navigate(['auth/register']);
  }
  onSubmit(form: NgForm, event: Event) {
    // this.signIn()
    (form.controls['mCode'] as UntypedFormControl).markAsDirty();
    (form.controls['mobile'] as UntypedFormControl).markAsDirty();
    (form.controls['currencyChosen'] as UntypedFormControl).markAsDirty();
    // this.validateCheckbox();
    this.additionalInfo();
  }
}
