<div class="default-padding">
  <div class="centered-container flex-container logsign forget">
    <div class="logsign-container">
      <h2 class="logsign-description">Please enter your new password:</h2>
      <form (ngSubmit)="resetPassword($event)" #f="ngForm" class="logsign-form">
        <app-alert-box [location]="'logsign'" class="mainAlert"></app-alert-box>
        <div class="logsign-input">
          <input name="newPass" type="password" class="input" placeholder="New Password" required [(ngModel)]="newPassVal"
            #newPass="ngModel">
        </div>
        <div class="logsign-input">
          <input name="confPass" type="password" class="input" placeholder="Confirm Password" required [(ngModel)]="confPassVal"
            #newPass="ngModel">
        </div>
        <div class="logsign-extra flex-container space-between">
          <div class="message"><a [routerLink]="['/login']">Back to login?</a></div>
        </div>
        <div class="logsign-input submit">
          <input type="submit" class="input" value="Reset Password">
        </div>
      </form>
    </div>
  </div>
</div>